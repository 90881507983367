import React, { Component } from 'react';
// import info_icon from "../New_Icons/Info_Jessie.png";
// import info_icon from "../New_Icons/SVG_Icons/info.svg";

// import edit_icon from "../Edit_Icon.png"
import edit_icon from '../New_Icons/SVG_Icons/edit.svg';

import { Link } from 'react-router-dom';

// import star_icon_empty from "../New_Icons/Star_Empty_Jessie.png"
// import star_icon_full from "../New_Icons/Star_Full_Jessie.png"
import star_icon_empty from '../New_Icons/SVG_Icons/star.svg';
import star_icon_full from '../New_Icons/SVG_Icons/star_filled.svg';
import feedback_icon from '../New_Icons/feedbackicon_1.png';
import '../App.css';

const SYRIAC_W = 'Serto Urhoy Font';
// const SYRIAC_E = 'AA- East Syriac Marcus';
const SYRIAC_E = 'Syriac East Adiabene';

class NameBox extends Component {
	state = {
		// isLiked: this.props.this.props.fave_list.find(name => name.id === this.props.name.id) ? true : false
		isClicked: false
	};
	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	renderBigBox = () => {
		const {
			name,
			user,
			dialect,
			fave_list,
			onConvertDialect,
			onAddFavorite,
			expandingName,
			onToggleInfo
		} = this.props;
		// console.log('name:', name);
		return (
			<div className="name-box-big">
				{/* onClick={() => this.props.onToggleInfo(this.props.name.id)} */}
				<div className="n-row">
					<div className="n-col m" onClick={() => onToggleInfo(name.id)}>
						{name.eng_word}
					</div>
					<div
						className="n-col s"
						style={dialect === 'Eastern' ? { fontFamily: SYRIAC_E } : { fontFamily: SYRIAC_W }}
						onClick={() => onToggleInfo(name.id)}
					>
						{onConvertDialect(name.syr_word, dialect)}
					</div>
					<div className="n-col arab-col" onClick={() => onToggleInfo(name.id)}>
						{name.arb_word}
					</div>
					<div className="n-col m x">
						<small onClick={() => onToggleInfo(name.id)}>{name.meaning}</small>
					</div>

					<div className="s-icon-col">
						{user && user.level === 'admin' ? (
							<React.Fragment>
								<Link to={`/edit/${name.id}`} onClick={this.scrollToTop}>
									<img
										src={edit_icon}
										style={{
											width: '28px',
											marginLeft: '5px',
											marginTop: '5px',
											cursor: 'pointer',
											marginTop: 8
										}}
										alt="Edit logo"
									/>
								</Link>
							</React.Fragment>
						) : null}
						{this.props.fave_list.find((listName) => listName.id === name.id) ? (
							<img
								src={star_icon_full}
								style={{
									width: '28px',
									marginLeft: '5px',
									marginTop: '5px',
									cursor: 'pointer'
								}}
								alt="like logo"
								onClick={() => onAddFavorite(name)}
							/>
						) : (
							<img
								src={star_icon_empty}
								style={{
									width: '28px',
									marginLeft: '5px',
									marginTop: '5px',
									cursor: 'pointer'
								}}
								alt="like logo"
								onClick={() => onAddFavorite(name)}
							/>
						)}
					</div>
				</div>
				<div
					className="more-info-container"
					// onClick={() => onToggleInfo(name.id)}
					onClick={() => onToggleInfo(name.id)}
				>
					<div className="title text-info border-bottom" style={{ textAlign: 'center', marginTop: '10px' }} />
					<div className="info-table">
						<div className="i-row">
							<div className="i-col l">Gender:</div>
							<div className="i-col r">{name.gender}</div>
						</div>
						{name.variations && name.variations !== '' ? (
							<div className="i-row">
								<div className="i-col l">Variations:</div>
								<div className="i-col r">{name.variations}</div>
							</div>
						) : null}
						{name.eng_desc !== '' ? (
							<div className="i-row">
								<div className="i-col l">Description:</div>
								<div className="i-col r">{name.eng_desc}</div>
							</div>
						) : null}
						{name.arb_word !== '' ? (
							<div className="i-row">
								<div className="i-col l a">الكلمة:</div>
								<div className="i-col r a">{name.arb_word}</div>
							</div>
						) : null}
						{name.arb_desc !== '' ? (
							<div className="i-row">
								<div className="i-col l">المعنى:</div>
								<div className="i-col r am">{name.arb_desc}</div>
							</div>
						) : null}
						{name.src !== '' ? (
							<React.Fragment>
								<div className="i-row">
									<div className="i-col l">Source:</div>
									<div className="i-col r">
										{name.type} {name.title_eng ? ':' + name.title_eng : null}
									</div>
								</div>
								<div className="i-row">
									<div className="i-col l" />
									<div className="i-col r">
										{name.title_arb ? name.title_arb : null} -{' '}
										{name.title_syr ? name.title_syr : null}
									</div>
								</div>
							</React.Fragment>
						) : null}
						<div className="i-row">
							<div className="i-col l" />
							<Link
								to="/contact"
								className="i-col r am"
								onClick={() =>
									this.props.onFeedbackChange(
										'Feedback on the name: ' + name.eng_word + ' - ' + name.syr_word + '\n'
									)}
							>
								Give Feedback<img src={feedback_icon} height={20} />
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	};
	renderBox = () => {
		const {
			name,
			user,
			dialect,
			fave_list,
			onConvertDialect,
			onAddFavorite,
			expandingName,
			onToggleInfo
		} = this.props;
		return (
			<div className="name-box">
				<div className="n-row">
					<div className="n-col m" onClick={() => onToggleInfo(name.id)}>
						{name.eng_word}
					</div>

					<div
						className="n-col s"
						style={dialect === 'Eastern' ? { fontFamily: SYRIAC_E } : { fontFamily: SYRIAC_W }}
						onClick={() => onToggleInfo(name.id)}
					>
						{onConvertDialect(name.syr_word, dialect)}
					</div>

					<div className="n-col arab-col s-arb-col" onClick={() => onToggleInfo(name.id)}>
						{name.arb_word}
					</div>
					<div className=" n-col m x" onClick={() => onToggleInfo(name.id)}>
						<small>{name.meaning}</small>
					</div>

					<div className="s-icon-col" id="star_step">
						{user && user.level === 'admin' ? (
							<React.Fragment>
								<Link to={`/edit/${name.id}`} onClick={this.scrollToTop}>
									<img
										src={edit_icon}
										style={{
											width: '28px',
											marginLeft: '5px',
											marginTop: '5px',
											cursor: 'pointer',
											marginTop: 8
										}}
										alt="Edit logo"
									/>
								</Link>
							</React.Fragment>
						) : null}
						{this.props.fave_list.find((listName) => listName.id === name.id) ? (
							<img
								src={star_icon_full}
								style={{
									width: '28px',
									marginLeft: '5px',
									marginTop: '5px',
									cursor: 'pointer'
								}}
								alt="like logo"
								onClick={() => onAddFavorite(name)}
							/>
						) : (
							<img
								src={star_icon_empty}
								style={{
									width: '28px',
									marginLeft: '5px',
									marginTop: '5px',
									cursor: 'pointer'
								}}
								alt="like logo"
								onClick={() => onAddFavorite(name)}
							/>
						)}
					</div>
				</div>
			</div>
		);
	};
	render() {
		const { name, expandingName } = this.props;
		return expandingName.isExpanded && expandingName.id === name.id ? this.renderBigBox() : this.renderBox();
	}
}

export default NameBox;
