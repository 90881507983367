import React from "react";
import {
  initGA,
  capturePageview,
  captureEvent
} from "../Analytics/googleAnalytics";

import filter_icon from "../New_Icons/SVG_Icons/filter.svg";
import cancel_filter_icon from "../New_Icons/SVG_Icons/cancle_filter.svg";

const FilterHeader = props => {
  return props.filterToggled ? (
    <div className="filter-header">
      <div onClick={props.onToggleFilters} style={{ cursor: "pointer" }}>
        <img src={cancel_filter_icon} alt="filter icon" height="40px" />
        <span style={{ marginLeft: "5px", color: "#4f9ca5" }}>Filters</span>
      </div>
    </div>
  ) : (
    <div className="filter-header" style={{ cursor: "pointer" }}>
      <div onClick={props.onToggleFilters}>
        <img src={filter_icon} alt="filter icon" height="40px" />
        <span style={{ marginLeft: "5px", color: "#4f9ca5" }}>Filters</span>
      </div>
    </div>
  );
};

export default FilterHeader;
