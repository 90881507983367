import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Joi from 'joi-browser';
import http from '../services/httpService';
import config from '../services/config.js';
import cookies from 'react-cookies';
import { initGA, capturePageview } from '../Analytics/googleAnalytics';

class AdminAdd extends Component {
	state = {};
	constructor(props) {
		super(props);
		this.state = {
			name: {
				eng_word: '',
				meaning: '',
				eng_desc: '',
				gender: 'Select_One',
				arb_desc: '',
				syr_word: '',
				arb_word: '',
				variations: '',
				src: '',
				status: '',
				created_by_user_id: ''
			},
			cat_arr: [],
			errors: {},
			done: false,
			user: { level: 'admin' }
		};
	}
	componentDidMount() {
		capturePageview();
	}
	schema = {
		eng_word: Joi.string().min(3).max(25).required().label('Name in English'),
		syr_word: Joi.string().min(3).max(25).required().label('Name in Syriac'),
		arb_word: Joi.string().min(3).max(25).required().label('Name in Arabic'),
		meaning: Joi.string().max(40).required().label('Meaning'),
		// eng_desc: Joi.string().max(400).label("Description in English"),
		// arb_desc: Joi.string().max(400).label("Description in Arabic"),
		src: Joi.string().max(100).label('Source'),
		// variations: Joi.string().max(100).label("Variations"),
		gender: Joi.string().max(7).required().label('Gender'),
		status: Joi.string().required().label('Status')
	};
	categories = [
		{ id: 10, cat: 'historical' },
		{ id: 11, cat: 'traditional' },
		{ id: 12, cat: 'poetic' },
		{ id: 13, cat: 'compound' },
		{ id: 14, cat: 'nature' },
		{ id: 15, cat: 'religious' },
		// { id: 16, cat: "non_syriac" },
		{ id: 17, cat: 'short' }
	];
	statuses = [ { id: 1, status: 'approved' }, { id: 2, status: 'pending' } ];
	handleFieldChange = (e) => {
		const name = { ...this.state.name };
		const type = e.target.id;
		const value = e.target.value;

		name[type] = value;
		this.setState({ name });
	};
	handleGenderChange = (e) => {
		const name = { ...this.state.name };
		name.gender = e.target.value;
		this.setState({ name });
	};
	validate = () => {
		const name = {
			eng_word: this.state.name.eng_word,
			syr_word: this.state.name.syr_word,
			arb_word: this.state.name.arb_word,
			meaning: this.state.name.meaning,
			gender: this.state.name.gender,
			status: this.state.name.status
		};
		const result = Joi.validate(name, this.schema, { abortEarly: false });
		if (!result.error) return null;

		const errors = {};
		for (let error of result.error.details) {
			errors[error.path[0]] = error.message;
		}
		return errors;
	};
	handleSubmit = (e) => {
		this.scrollToTop();
		e.preventDefault();
		const cat_arr = [ ...this.state.cat_arr ];
		const errors = this.validate();
		const {
			eng_word,
			eng_desc,
			arb_desc,
			arb_word,
			syr_word,
			src,
			variations,
			gender,
			status,
			meaning
		} = this.state.name;
		const name = {
			eng_word,
			eng_desc,
			arb_desc,
			arb_word,
			syr_word,
			src,
			variations,
			gender,
			status,
			meaning,
			created_by_user_id: this.props.user.id
		};
		if (!errors) {
			this.setState({ errors: {} });
			// var name = {
			//     ...this.state.name
			// }
			http
				.post(
					config.API_ENDPOINT + '/names',
					{ name, cat_arr },
					{ headers: { authorization: 'Bearer ' + cookies.load('token') } }
				)
				.then((result) => {
					// console.log('admin add:', result);
					if (result.status === 200) {
						this.setState({ done: true });
					}
				});
		} else {
			this.setState({ errors });
		}
	};
	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	handleCatChange = (e) => {
		var cat_arr = this.state.cat_arr;
		const value = e.target.value;
		if (cat_arr.includes(value)) {
			cat_arr = this.state.cat_arr.filter((cat) => cat !== value);
		} else {
			cat_arr.push(value);
		}
		this.setState({ cat_arr });
	};
	handleStatusChange = (e) => {
		var name = { ...this.state.name };
		name.status = e.target.value;
		this.setState({ name });
	};
	handleExitMessage = () => {
		this.setState({ errors: {} });
	};
	categories_list = () => {
		return this.categories.map((category) => (
			<div key={category.id} className="custom-control custom-switch">
				<input
					type="checkbox"
					className="custom-control-input"
					id={category.id}
					value={category.id || 'adsf'}
					checked={this.state.cat_arr.find((cat) => cat === category.id)}
					onChange={this.handleCatChange}
				/>
				<label className="custom-control-label" htmlFor={category.id}>
					{category.cat}
				</label>
			</div>
		));
	};
	statuses_list = () => {
		return this.statuses.map((status) => (
			<div key={status.id} className="custom-control custom-radio text-info">
				<input
					type="radio"
					id={status.id}
					name="customRadio"
					className="custom-control-input"
					value={status.status}
					checked={this.state.name.status === status.status}
					onChange={this.handleStatusChange}
				/>
				<label className="custom-control-label" htmlFor={status.id}>
					{status.status}
				</label>
			</div>
		));
	};
	render() {
		if (!this.props.isLoggedIn || this.props.user.level !== 'admin' || this.state.done) {
			return <Redirect to="/main" />;
		} else {
			return (
				<div className="edit-wrapper">
					<p style={{ textAlign: 'center' }}>Adding New Name</p>
					<div className="edit-container">
						<div className="e-col">
							<div className="input-container">
								<p>Name (English)</p>
								<input
									type="text"
									className="single-input"
									id="eng_word"
									placeholder="Name in English..."
									onChange={this.handleFieldChange}
									value={this.state.name.eng_word || ''}
								/>
							</div>
							{this.state.errors.eng_word && (
								<div className="message-container" onClick={this.handleExitMessage}>
									("X) {this.state.errors.eng_word}
								</div>
							)}
							<div className="input-container">
								<p>Name (Syriac)</p>
								<input
									type="text"
									className="single-input"
									id="syr_word"
									placeholder="Name in Syriac..."
									onChange={this.handleFieldChange}
									value={this.state.name.syr_word || ''}
								/>
							</div>
							{this.state.errors.syr_word && (
								<div className="message-container" onClick={this.handleExitMessage}>
									("X) {this.state.errors.syr_word}
								</div>
							)}

							<div className="input-container">
								<p>Name (Arabic)</p>
								<input
									type="text"
									className="single-input"
									id="arb_word"
									placeholder="Name in Arabic..."
									onChange={this.handleFieldChange}
									value={this.state.name.arb_word || ''}
								/>
							</div>
							{this.state.errors.arb_word && (
								<div className="message-container" onClick={this.handleExitMessage}>
									("X) {this.state.errors.arb_word}
								</div>
							)}
							<div className="input-container">
								<p>Meaning</p>
								<input
									type="text"
									className="single-input"
									id="meaning"
									placeholder="Meaning..."
									onChange={this.handleFieldChange}
									value={this.state.name.meaning || ''}
								/>
							</div>
							{this.state.errors.meaning && (
								<div className="message-container" onClick={this.handleExitMessage}>
									("X) {this.state.errors.meaning}
								</div>
							)}
							<div className="input-container">
								<p>Variations</p>
								<input
									type="text"
									className="single-input"
									id="variations"
									placeholder="Variations..."
									onChange={this.handleFieldChange}
									value={this.state.name.variations || ''}
								/>
							</div>
							{this.state.errors.variations && (
								<div className="message-container" onClick={this.handleExitMessage}>
									("X) {this.state.errors.variations}
								</div>
							)}
							<div className="input-container">
								<p>Description (English)</p>
								<textarea
									className="single-input"
									id="eng_desc"
									rows="3"
									placeholder="Add more description in English"
									onChange={this.handleFieldChange}
									value={this.state.name.eng_desc || ''}
								/>
							</div>
							{this.state.errors.eng_desc && (
								<div className="message-container" onClick={this.handleExitMessage}>
									("X) {this.state.errors.eng_desc}
								</div>
							)}
						</div>
						<div className="e-col">
							<div className="input-container">
								<p>Description (Arabic)</p>
								<textarea
									className="single-input"
									id="arb_desc"
									rows="3"
									placeholder="Add more description in Arabic"
									onChange={this.handleFieldChange}
									value={this.state.name.arb_desc || ''}
								/>
							</div>
							{this.state.errors.arb_desc && (
								<div className="message-container" onClick={this.handleExitMessage}>
									("X) {this.state.errors.arb_desc}
								</div>
							)}

							<div className="input-container">
								<p>Source</p>
								<input
									type="text"
									className="single-input"
									id="src"
									placeholder="Source..."
									onChange={this.handleFieldChange}
									value={this.state.name.src || ''}
								/>
							</div>
							{this.state.errors.src && (
								<div className="message-container" onClick={this.handleExitMessage}>
									("X) {this.state.errors.src}
								</div>
							)}

							<div className="input-container">
								<p>Gender</p>
								<select
									className=""
									id="gender"
									onChange={this.handleGenderChange}
									value={this.state.name.gender}
								>
									<option value="Select_One" disabled>
										Select One
									</option>
									<option value="male">Male</option>
									<option value="female">Female</option>
									{/* <option value="neutral">Neutral</option> */}
								</select>
							</div>
							{this.state.errors.gender && (
								<div className="message-container" onClick={this.handleExitMessage}>
									("X) {this.state.errors.gender}
								</div>
							)}
							<div className="" style={{ textAlign: 'left' }}>
								<div className="">{this.categories_list()}</div>
								<div className="">{this.statuses_list()}</div>
								{this.state.errors.status && (
									<div className="message-container" onClick={this.handleExitMessage}>
										("X) {this.state.errors.status}
									</div>
								)}
							</div>
						</div>
						<div className="edit-button-container">
							<button className="add-btn" onClick={this.handleSubmit}>
								Submit
							</button>
							<Link to="/main">
								<button className="add-btn" onClick={this.scrollToTop}>
									Cancel
								</button>
							</Link>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default AdminAdd;
