import React, { Component } from "react";
import _ from "lodash";
import next_icon from "../Next_Icon.png";
import prev_icon from "../Prev_Icon.png";
import {
  initGA,
  capturePageview,
  captureEvent
} from "../Analytics/googleAnalytics";

class Pagination extends Component {
  state = {};

  handleNext = () => {};
  render() {
    // const { namesCount, pageSize } = this.props;
    const pagesCount =
      Math.ceil(this.props.namesCount / this.props.pageSize) + 1;
    var pagesArr = [];

    var start = this.props.startPage;
    var end = 0;
    if (pagesCount - start > 5) {
      end = start + 5;
    } else {
      end = start + (pagesCount - start);
    }

    if (this.props.namesCount === 0) {
      start = 0;
      end = 0;
    }
    pagesArr = _.range(start, end);

    return (
      <div className="pagination-container">
        {start > 1 ? (
          <span
            onClick={() => {
              captureEvent("Navigation", "Pagination", "Previous");
              this.props.onPrev(start - 5);
            }}
          >
            <img
              src={prev_icon}
              alt="prev icon"
              height="25px"
              style={{ marginBottom: "8px", cursor: "pointer" }}
            />
          </span>
        ) : null}

        {pagesArr.map(page => (
          <div
            key={page}
            className={
              this.props.currPage === page ? "page-icon active" : "page-icon"
            }
            onClick={() => {
              captureEvent("Navigation", "Pagination", "Page Number");
              this.props.onSelectPage(page);
            }}
          >
            {page}
          </div>
        ))}
        {pagesCount - start > 5 ? (
          <span
            onClick={() => {
              captureEvent("Navigation", "Pagination", "Next");
              this.props.onNext(end);
            }}
          >
            <img
              src={next_icon}
              alt="next icon"
              height="25px"
              style={{ marginBottom: "8px", cursor: "pointer" }}
            />
          </span>
        ) : null}
      </div>
    );
  }
}

export default Pagination;
