import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Joi from 'joi-browser';
import http from '../services/httpService';
import config from '../services/config.js';
import cookies from 'react-cookies';
import { initGA, capturePageview, captureEvent } from '../Analytics/googleAnalytics';

class Add extends Component {
	state = {};
	constructor(props) {
		capturePageview();
		super(props);
		this.state = {
			data: {
				eng_word: '',
				meaning: '',
				eng_desc: '',
				gender: '',
				created_by_user_id: ''
			},
			errors: {},
			done: false,
			user: this.props.user
		};
		this.scrollToTop();
	}
	schema = {
		eng_word: Joi.string().min(3).max(25).required().label('Name'),
		meaning: Joi.string().max(40).required().label('Meaning'),
		// eng_desc: Joi.string().max(400).required().label("More Info"),
		gender: Joi.string().required().label('Gender')
	};
	handleNameChage = (e) => {
		const data = { ...this.state.data };
		data.eng_word = e.target.value;
		this.setState({ data });
	};
	handleMeaningChage = (e) => {
		const data = { ...this.state.data };
		data.meaning = e.target.value;
		this.setState({ data });
	};
	handleDescChage = (e) => {
		const data = { ...this.state.data };
		data.eng_desc = e.target.value;
		this.setState({ data });
	};
	handleGenderChange = (e) => {
		const data = { ...this.state.data };
		data.gender = e.target.value;
		this.setState({ data });
	};
	validate = () => {
		const result = Joi.validate(
			{
				eng_word: this.state.data.eng_word,
				meaning: this.state.data.meaning,
				gender: this.state.data.gender
			},
			this.schema,
			{ abortEarly: false }
		);
		if (!result.error) return null;

		const errors = {};
		for (let error of result.error.details) {
			errors[error.path[0]] = error.message;
		}
		return errors;
	};
	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	handleSubmit = (e) => {
		this.scrollToTop();
		captureEvent('Navigation', 'Submitted Add Name', 'N/A');
		e.preventDefault();
		const errors = this.validate();
		const { user } = this.props;
		if (!errors) {
			this.setState({ errors: {} });

			var name = {
				...this.state.data,
				arb_desc: '',
				syr_word: '',
				arb_word: '',
				variations: '',
				src: cookies.load('first_name') + ' ID: ' + cookies.load('id'),
				status: 'pending',
				created_by_user_id: user.id
			};
			var cat_add_arr = []; //to be used by admin in the future.

			http
				.post(
					config.API_ENDPOINT + '/names',
					{ name, cat_add_arr },
					{ headers: { authorization: 'Bearer ' + cookies.load('token') } }
				)
				.then((result) => {
					// console.log('add result:', result);
					if (result.status === 200) {
						this.setState({ done: true });
					}
				});
		} else {
			this.setState({ errors });
		}
	};
	handleExitMessage = () => {
		this.setState({ errors: {} });
	};
	render() {
		if (this.props.isLoggedIn) {
			if (this.state.done)
				return (
					<Redirect
						to={{
							pathname: '/success',
							state: { from: this.props.location }
						}}
					/>
				);
			return (
				<div className="add-wrapper">
					<div className="add-container">
						<div className="input-container">Add Name:</div>
						<div className="input-container">
							<input
								type="text"
								className="single-input"
								id="name"
								placeholder="Name..."
								onChange={this.handleNameChage}
								value={this.state.data.eng_word || ''}
							/>
						</div>
						{this.state.errors.eng_word && (
							<div className="message-container" onClick={this.handleExitMessage}>
								(X) {this.state.errors.eng_word}
							</div>
						)}

						<div className="input-container">
							<input
								type="text"
								className="single-input"
								id="name"
								placeholder="Meaning..."
								onChange={this.handleMeaningChage}
								value={this.state.data.meaning || ''}
							/>
						</div>
						{this.state.errors.meaning && (
							<div className="message-container" onClick={this.handleExitMessage}>
								(X) {this.state.errors.meaning}
							</div>
						)}

						<div className="input-container">
							<textarea
								className="long-input"
								placeholder="Add source, variations, etc..."
								onChange={this.handleDescChage}
								value={this.state.data.eng_desc || ''}
							/>
						</div>
						{this.state.errors.eng_desc && (
							<div className="message-container" onClick={this.handleExitMessage}>
								(X) {this.state.errors.eng_desc}
							</div>
						)}

						<div className="input-container">
							<span>Gender: </span>
							<select
								className="single-input"
								id="gender"
								onChange={this.handleGenderChange}
								defaultValue="select_one"
							>
								<option value="select_one" disabled>
									Select One
								</option>
								<option value="male">Male</option>
								<option value="female">Female</option>
								{/* <option value="neutral">Neutral</option> */}
							</select>
						</div>
						{this.state.errors.gender && (
							<div className="message-container" onClick={this.handleExitMessage}>
								(X) {this.state.errors.gender}
							</div>
						)}
						<div className="input-container">
							<button className="add-btn" onClick={this.handleSubmit}>
								Submit
							</button>
							<Link
								to="/main"
								onClick={() => captureEvent('Navigation', 'Cancelled Adding Name', 'Button')}
							>
								<button className="add-btn" onClick={this.scrollToTop}>
									Cancel
								</button>
							</Link>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<Redirect
					to={{
						pathname: '/login',
						state: { from: this.props.location }
					}}
				/>
			);
		}
	}
}

export default Add;
