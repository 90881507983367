import React from 'react';
import { Link } from 'react-router-dom';
import capni_logo from '../../capni_logo.jpg';
import betkanu_logo from '../../betkanu_logo.png';
import book_1 from '../../book_cover.jpg';
import capni_book_cover from '../../Photos/capni_book_cover.png';
import assyrianSyriac_book_cover from '../../Photos/AssyrianSyriacNames_book.jpg';

import logoNames from '../../New_Icons/Bet_Kanu_Names_logo_4.png';

import book_2 from '../../book_cover_2.jpg';
// import "../../App.css";
import './about.css';
import { checkPropTypes } from 'prop-types';

import { initGA, capturePageview } from '../../Analytics/googleAnalytics';
// const ROBOTO = 'Roboto Font';
import { FacebookShareButton, FacebookIcon, TwitterIcon, TwitterShareButton } from 'react-share';

const About = (props) => {
	props.onScrollToTop();
	capturePageview();
	return (
		<React.Fragment>
			<div className="about">
				<div className="title">ABOUT</div>
				<div className="sub-title">"Syriac for the digital age"</div>
				<div className="content-container">
					<span style={{ fontWeight: 'bold' }}>BET KANU Names</span> is the best place to find a unique Syriac
					name for your newborn. With multiple search options, and wide range of carefully curated sources,
					and fan-provided names, BET KANU Names will save you time and reduce the stress of finding the
					perfect name for the new addition of your family.
					<br />
					<br />
					Collect your favorite names by adding them to the cart, and Email them with your friends. All the
					names are provided in our beautiful Syriac Language with Eastern and Western font styles.
					<br />
					<br />You have a great name you want us to add?<Link to="/add"> Simply add it.</Link>
					<br />
					<br />
					Available soon on the App Store, and Google Play.
				</div>

				<div style={{ marginTop: 20 }}>
					<span>Share: </span>
					<FacebookShareButton
						url={'https://names.betkanu.com'}
						quote={
							'This is the best website to find Syriac names for your babies. Different filter options, details, and much more. '
						}
						hashtag={'#BETKANUNames'}
					>
						<FacebookIcon size={30} />
					</FacebookShareButton>
					<TwitterShareButton
						style={{ marginLeft: 20, marginRight: 20 }}
						url={'https://names.betkanu.com'}
						title={'BET KANU Names: the best website to find #Syriac names for your your babies'}
						hashtags={[ 'BETKANUNames', 'BETKANU' ]}
					>
						<TwitterIcon size={30} />
					</TwitterShareButton>
				</div>

				<div className="separator" />
				<div className="title">DEVELOPED BY</div>
				<div className="logo">
					<img src={betkanu_logo} alt="betkanu logo" height="50px" />
				</div>
				<div className="container">
					<div className="a-col">
						<span style={{ fontWeight: 'bold' }}>Web Developer:</span>
						<br />
						Sargon Saadi
					</div>
					<div className="a-col">
						<span style={{ fontWeight: 'bold' }}>Marketing Coordinator:</span>
						<br />
						Ramina Samuel
					</div>
				</div>
				<div className="container">
					<div className="a-col">
						<span style={{ fontWeight: 'bold' }}>Mobile Developer:</span>
						<br />
						Akkad Saadi
					</div>
					<div className="a-col">
						<span style={{ fontWeight: 'bold' }}>Visual Coordinator:</span>
						<br />
						Jessica Lahdow
					</div>
				</div>
				<div className="container">
					<div className="a-col">
						<span style={{ fontWeight: 'bold' }}>
							Mobile Front-End <br />Developer:
						</span>
						<br />
						Samir Shamoun
					</div>
					<div className="a-col">
						<span style={{ fontWeight: 'bold' }}>Graphic Designer:</span>
						<br />
						Ornina Shabo <br />
						Jessica Lahdow<br />
						Simely Malki
					</div>
				</div>
				<div className="separator" />

				<div className="title">SPONSORED BY</div>
				<div className="container">
					<div className="a-col">
						<img src={capni_logo} alt="capni 2" className="picture" />
					</div>
					<div className="a-col b">
						We thank CAPNI for their continues support to our projects. <br />
						<br />“CAPNI, as a Christian organization, seeks tolerance, & social justice for society, where
						minority & vulnerable people have a dignified existence and security” and keep the “Hope Alive”.<br />
						<br />Established in 1991.<br />
						<br />www.capni-iraq.org{' '}
					</div>
				</div>
				<div className="separator" />

				<div className="title">SOURCES</div>

				<div className="container">
					<div className="a-col">
						<img src={capni_book_cover} alt="book 1" className="picture" />
					</div>
					<div className="a-col b">
						<div style={{ fontWeight: 'bold', fontSize: 25 }}>ܢܒܥܐ ܕܫܡܗ̈ܐ</div>
						<div style={{ fontWeight: 'bold', fontSize: 25 }}>The Spring of Names</div>
						<br />Written by: Rev. Emanuel Youkhana & Romel Shimshon Samuel.
						<br />
						<br /> The authors of this book of Assyrian names are primarily concerned about using names for
						newly born Assyrian boys and girls which will not only identify them as unique persons and
						individuals, but at the same time will hopefully help to identify them as belonging to the
						Assyrian nation and culture.<br />
						<br />Written in 2010 and sponsored by CAPNI.{' '}
					</div>
				</div>
				<div className="container" style={{ marginTop: 20 }}>
					<div className="a-col">
						<img src={assyrianSyriac_book_cover} alt="book 1" className="picture" />
					</div>
					<div className="a-col b">
						<div style={{ fontWeight: 'bold', fontSize: 25 }}>ܫܡܗ̈ܐ ܣܘܪ̈ܝܝܐ ܐܬܘܪ̈ܝܐ</div>
						<div style={{ fontWeight: 'bold', fontSize: 25 }}>Names From The Assyrian Syriac Tradition</div>
						<br />Written by: Dr. Abrohom Lahdo.
						<br />Presented by Father Joseph Shabo.
						<br />
						<br />"I chose names from the garden of the ancient and modern tradition including the Sumerian,
						Akkadian, Babylonian, Assyrian, Aramean, Chaldean, and Syria. The tradition of Iraq, Syria, and
						Mesopotamia."" <br />
						<br />
						<br />Published by: Syriac Library of Aleppo. 2004
					</div>
				</div>

				{/* <div className="container">
                <div className="a-col">
                    <img src={book_2} alt="book 2" className="picture" />
                </div>
                <div className="a-col b">a;sdf a;dsfja kfads fadskfjasd;lfjasd; ;f asjf alsdfj alsdfj a;sf asf kas fasdjf asdjf ;asdljf asf askdjf al;dsfj asf asd fads fdsa fas fa;lsfj a;sdjf afkj </div>
            </div> */}
				<div onClick={props.onScrollToTop}>Go to top ^</div>
			</div>
		</React.Fragment>
	);
};

export default About;
