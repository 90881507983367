const steps = [
	{
		selector: '#logo_step',
		content: 'Welcome to BET KANU Names (BETA). Here is a Quick Guide!'
	},
	{
		selector: '#filters_step',
		content: 'Choose any combination of filters to narrow down the results.'
	},
	{
		selector: '#star_step',
		content: 'Save your favorite names by clicking on the Star icon.'
	},
	{
		selector: '#addname_step',
		content: 'You can add a name to our list : )'
	},
	// {
	//   selector: "#search_step",
	//   content: "You can search by name or meaning in English, Syriac, or Arabic."
	// },
	{
		selector: '#dialect_step',
		content: 'Switch between Eastern and Western Fonts'
	},
	{
		selector: '#result_step',
		content: 'Your feedback will help us improve.'
	},
	{
		selector: '#result_step',
		content: '...Enjoy searching!.'
	}
];

module.exports = steps;
