import React, { Component } from 'react';
import config from '../services/config.js';
import { GoogleLogin } from 'react-google-login';

import { Redirect } from 'react-router-dom';
import { initGA, capturePageview } from '../Analytics/googleAnalytics.js';
class Login extends Component {
	render() {
		capturePageview();

		const { from } = this.props.location.state || { from: { pathname: '/' } };
		return this.props.isLoggedIn ? (
			<Redirect to={from} />
		) : (
			<div className="login">
				<div className="support">
					{from.pathname === '/add' ? (
						<p>To add a name, please login with Google.</p>
					) : (
						<p>Easy and Secure Login.</p>
					)}
					<GoogleLogin
						clientId={config.GOOGLE_CLIENT_ID}
						buttonText="Login"
						onSuccess={this.props.onGoogleResponse}
						onFailure={this.props.onFailure}
					/>
				</div>
			</div>
		);
	}
}

export default Login;
