import React, { Component } from "react";
import { Link } from "react-router-dom";
class SuccessAdd extends Component {
  state = {};

  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };

    return (
      <div className="success-wrapper">
        <div className="success-container">
          {from.pathname === "/add" || from.pathname === "/adminadd" ? (
            <>
              <p>
                Thank you {this.props.user.first_name} for contributing to our
                database. It is being reviewed by our team.
              </p>
              <Link to="/main">
                <button className="add-btn">Home</button>
              </Link>
              <Link to="/add">
                <button className="add-btn">Add Another Name</button>
              </Link>
            </>
          ) : (
            <>
              <p>
                Thank you {this.props.user.first_name} for donating. We thank
                you sincerely.<br></br>Tawdi/Basima.
              </p>
              <Link to="/main">
                <button className="add-btn">Home</button>
              </Link>
              <Link to="/about">
                <button className="add-btn">Read more about us</button>
              </Link>
            </>
          )}
        </div>
      </div>
    );
  }
}
export default SuccessAdd;
