import ReactGA from "react-ga";

export const initGA = () => {
  ReactGA.initialize("UA-154732556-1");
};
export const capturePageview = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

export const captureEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label
  });
};
