import React, { Component } from 'react';
// import clear_icon from "../Clear_Icon_1.png";
// import clear_icon from "../New_Icons/Clear_Jessie.png";
import clear_icon from '../New_Icons/SVG_Icons/clear.svg';
import { initGA, capturePageview, captureEvent } from '../Analytics/googleAnalytics';
class Filters extends Component {
	render() {
		return this.props.filterToggled ? (
			<div className="filter-container" id="filters_step">
				<fieldset>
					<div className="f-col">
						<span className=" t">Categories:</span>
						<div className="custom-control custom-switch">
							<input
								type="checkbox"
								className="custom-control-input"
								id="religious"
								checked={this.props.categories.religious === true}
								onChange={() => {
									this.props.onCatChange('religious');
								}}
							/>
							<label className="custom-control-label" htmlFor="religious">
								<span className="bold f">Religious</span>
							</label>
						</div>
						<div className="custom-control custom-switch">
							<input
								type="checkbox"
								className="custom-control-input"
								id="traditional"
								checked={this.props.categories.traditional === true}
								onChange={() => this.props.onCatChange('traditional')}
							/>
							<label className="custom-control-label" htmlFor="traditional">
								<span className="bold f">Traditional</span>
							</label>
						</div>
						<div className="custom-control custom-switch">
							<input
								type="checkbox"
								className="custom-control-input"
								id="historical"
								checked={this.props.categories.historical === true}
								onChange={() => this.props.onCatChange('historical')}
							/>
							<label className="custom-control-label" htmlFor="historical">
								<span className="bold f">Historical</span>
							</label>
						</div>
					</div>
					<div className="f-col">
						<div className="custom-control custom-switch">
							<input
								type="checkbox"
								className="custom-control-input"
								disabled=""
								id="creative"
								checked={this.props.categories.creative === true}
								onChange={() => this.props.onCatChange('creative')}
							/>
							<label className="custom-control-label" htmlFor="creative">
								<span className="bold f">Compound</span>
							</label>
						</div>

						<div className="custom-control custom-switch">
							<input
								type="checkbox"
								className="custom-control-input"
								id="short"
								checked={this.props.categories.short === true}
								onChange={() => this.props.onCatChange('short')}
							/>
							<label className="custom-control-label" htmlFor="short">
								<span className="bold f">Short</span>
							</label>
						</div>
						<div className="custom-control custom-switch">
							<input
								type="checkbox"
								className="custom-control-input"
								disabled=""
								id="poetic"
								checked={this.props.categories.poetic === true}
								onChange={() => this.props.onCatChange('poetic')}
							/>
							<label className="custom-control-label" htmlFor="poetic">
								<span className="bold f">Poetic</span>
							</label>
						</div>
						<div className="custom-control custom-switch">
							<input
								type="checkbox"
								className="custom-control-input"
								disabled=""
								id="nature"
								checked={this.props.categories.nature === true}
								onChange={() => this.props.onCatChange('nature')}
							/>
							<label className="custom-control-label" htmlFor="nature">
								<span className="bold f">Nature</span>
							</label>
						</div>
					</div>
					<div className="f-col">
						<span className=" t">Gender:</span>
						<div className="custom-control custom-radio">
							<input
								type="radio"
								id="male"
								name="male"
								className="custom-control-input"
								checked={this.props.gender === 'male'}
								onChange={() => this.props.onGenderChange('male')}
							/>
							<label className="custom-control-label" htmlFor="male">
								<span className="bold f">Male</span>
							</label>
						</div>
						<div className="custom-control custom-radio">
							<input
								type="radio"
								id="female"
								name="female"
								className="custom-control-input"
								checked={this.props.gender === 'female'}
								onChange={() => this.props.onGenderChange('female')}
							/>
							<label className="custom-control-label" htmlFor="female">
								<span className="bold f">Female</span>
							</label>
						</div>
						{/* <div className="custom-control custom-radio">
              <input
                type="radio"
                id="neutral"
                name="neutral"
                className="custom-control-input"
                checked={this.props.gender === "neutral"}
                onChange={() => this.props.onGenderChange("neutral")}
              />
              <label className="custom-control-label" htmlFor="neutral">
                <span className="bold f">Neutral</span>
              </label>
            </div> */}
					</div>
					{this.props.user.level === 'admin' ? (
						<div className="f-col">
							<span className=" t">Status:</span>
							<div className="custom-control custom-radio">
								<input
									type="radio"
									id="approved"
									name="approved"
									className="custom-control-input"
									checked={this.props.status === 'approved'}
									onChange={() => this.props.onStatusChange('approved')}
								/>
								<label className="custom-control-label" htmlFor="approved">
									<span className="bold f">Approved</span>
								</label>
							</div>
							<div className="custom-control custom-radio">
								<input
									type="radio"
									id="pending"
									name="pending"
									className="custom-control-input"
									checked={this.props.status === 'pending'}
									onChange={() => this.props.onStatusChange('pending')}
								/>
								<label className="custom-control-label" htmlFor="pending">
									<span className="bold f">Pending</span>
								</label>
							</div>
							{/* <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="empty"
                  name="empty"
                  className="custom-control-input"
                  checked={this.props.status === ""}
                  onChange={() => this.props.onStatusChange("")}
                />
                <label className="custom-control-label" htmlFor="empty">
                  <span className="text-info bold">Empty</span>
                </label>
              </div> */}
						</div>
					) : null}

					{this.props.filtersChosen ? (
						<div className="clear-container text-warning" onClick={this.props.onReset}>
							<img src={clear_icon} alt="clear icon" height="30px" />
							CLEAR FILTERS
						</div>
					) : null}
				</fieldset>
			</div>
		) : null;
	}
}

export default Filters;
