import React, { Component } from "react";

class Login extends Component {
  state = {};
  render() {
    return <h1>Page Not Found</h1>;
  }
}

export default Login;
