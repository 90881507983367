import React, { Component } from 'react';
import http from '../services/httpService';
import config from '../services/config.js';
import { Link } from 'react-router-dom';
import cookies from 'react-cookies';
import star_icon_full from '../Star_Icon_Full.png';
// import delete_icon from "../New_Icons/Delete_Jessie.png";
import delete_icon from '../New_Icons/SVG_Icons/delete.svg';
import prev_icon from '../Prev_Icon.png';
import logo from '../New_Icons/Dargushto_Logo_header.png';

import loading_gif from '../New_Icons/loading_2.gif';

import Joi from 'joi-browser';
import { GoogleLogin } from 'react-google-login';
import { initGA, capturePageview, captureEvent } from '../Analytics/googleAnalytics';
// import { FacebookShareButton, FacebookIcon } from 'react-share';

import '../App.css';

const SYRIAC_W = 'Serto Urhoy Font';
// const SYRIAC_E = 'AA- East Syriac Marcus';
const SYRIAC_E = 'Syriac East Adiabene';

class Favorite extends Component {
	state = {};
	constructor(props) {
		super(props);
		this.state = {
			list: [],
			user: {},
			email: '',
			message: '',
			errors: {},
			loading: false
		};
		this.scrollToTop();
	}

	schema = {
		email: Joi.string().min(3).max(100).required().email().label('Email')
	};
	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	componentDidMount() {
		capturePageview();
	}
	handleEmailChange = (e) => {
		var email = e.target.value;
		this.setState({ email });
	};
	validate = () => {
		const result = Joi.validate({ email: this.state.email }, this.schema);
		if (!result.error) return null;

		const errors = {};
		for (let error of result.error.details) {
			errors[error.path[0]] = error.message;
		}
		return errors;
	};
	handleEmail = () => {
		var errors = this.validate();
		captureEvent('Social', 'Clicked Send Email', 'Button');
		if (errors) {
			this.setState({ errors });
		} else {
			var data = {
				toEmail: this.state.email,
				list: this.props.fave_list,
				email: this.state.email
			};

			// const email_url = this.props.isLoggedIn ? "/emailuser" : "/emailguest";
			const email_url = this.props.isLoggedIn ? '/emailuser_grid' : '/emailguest_grid';

			http
				.post(config.API_ENDPOINT + email_url, data, {
					headers: { authorization: 'Bearer ' + cookies.load('token') }
				})
				.then((response) => {
					let message = '';
					response.data.result
						? (message = 'Email Sent Successfully')
						: (message = 'Error Sending an Email!');
					if (response.data.result) {
						// subscribing the email to monthly newsletter
						http.post(config.API_ENDPOINT + '/subscribe', data).then((res) => {});
					}
					this.setState({ message, loading: false, errors: {} });
				});

			this.setState({ loading: true });
		}
	};
	handleExitMessage = () => {
		this.setState({ errors: {}, message: '' });
	};
	handleConvertDialect = (word, dialect) => {
		if (dialect === 'Eastern') {
			return word
				.replace(/\u0730/g, '\u0732')
				.replace(/\u0733/g, '\u0735')
				.replace(/\u0736/g, '\u0739')
				.replace(/\u073A/g, '\u0738');
		} else {
			return word
				.replace(/\u0732/g, '\u0730')
				.replace(/\u0735/g, '\u0733')
				.replace(/\u0739/g, '\u0736')
				.replace(/\u0738/g, '\u073A');
		}
	};
	List = () => {
		return (
			<React.Fragment>
				{this.props.fave_list.map((name) => (
					<div key={name.id} className="fave-content">
						<div className="n-col n">
							<p>{name.eng_word}</p>
						</div>
						<div className="n-col s">
							<div
								style={
									this.props.dialect === 'Eastern' ? (
										{ fontFamily: SYRIAC_E }
									) : (
										{ fontFamily: SYRIAC_W }
									)
								}
							>
								{this.handleConvertDialect(name.syr_word, this.props.dialect)}
							</div>
						</div>
						<div className="n-col arab-col n">
							<p>{name.arb_word}</p>
						</div>
						<div className="n-col">
							<small>{name.meaning}</small>
						</div>
						<div style={{ marginRight: 20 }}>
							<img
								onClick={() => {
									captureEvent('Favorties', 'Deleted Star', 'At Cart page');
									this.props.onRemoveFavorite(name.id);
								}}
								src={delete_icon}
								alt="delete icon"
								height="20px"
								style={{ cursor: 'pointer' }}
							/>
						</div>
					</div>
				))}
				{!this.props.isLoggedIn ? (
					<div className="text-info">
						<span style={{ marginRight: '5px' }}>Login Here To Save The List</span>
						<span
							onClick={() => {
								captureEvent('Auth', 'Clicked Login', 'From Favorites');
								this.props.onSaveList();
							}}
						>
							<GoogleLogin
								clientId={config.GOOGLE_CLIENT_ID}
								buttonText="Login"
								onSuccess={this.props.onGoogleResponse}
								onFailure={this.props.onFailure}
							/>
						</span>
					</div>
				) : null}
			</React.Fragment>
		);
	};
	render() {
		return (
			<div className="fave-container">
				<div className="fave-title">
					{this.state.errors.email ? (
						<div className="message-container" onClick={this.handleExitMessage}>
							{this.state.errors.email} (X)
							{/* <img src={cancel_info_icon} alt="cancel icon" height="20px" onClick={this.handleExitMessage} /> */}
						</div>
					) : null}
					{this.state.message.length > 0 ? (
						<div className="message-container" onClick={this.handleExitMessage}>
							{this.state.message} (X)
							{/* <img src={cancel_info_icon} alt="cancel icon" height="20px" onClick={this.handleExitMessage} /> */}
						</div>
					) : null}
					{this.state.loading ? <img src={loading_gif} alt="loading.." height="50px" /> : null}
				</div>
				<div className="fave-content" style={{ justifyContent: 'center' }}>
					<div style={{ width: '100%' }}>
						<input
							className="email-input"
							name="emai"
							placeholder="Email List To A Friend"
							value={this.state.email}
							onChange={this.handleEmailChange}
						/>
						<button
							className="email-submit"
							name="submit"
							value="submit"
							onClick={this.handleEmail}
							disabled={this.props.fave_list.length === 0}
						>
							SEND
						</button>
					</div>
					{/* <FacebookShareButton url={'https://dargushti-social-photos.s3.us-east-2.amazonaws.com/distortion.png'}>
            <FacebookIcon size={24} round={true} />
          </FacebookShareButton> */}
				</div>
				<div className="fave-body">
					{this.props.fave_list.length === 0 ? (
						<div className="explain-container">
							<div>
								You can collect your favorite names in your list by clicking on the star{' '}
								<img src={star_icon_full} alt="star icon" height="20px" />
							</div>
							<br />
							<Link to="/main" style={{ textDecoration: 'none' }}>
								<img src={prev_icon} alt="back" height="20px" />
								<span className="back">&nbsp;Back to Search</span>
							</Link>
						</div>
					) : (
						this.List()
					)}
				</div>
			</div>
		);
	}
}

export default Favorite;
