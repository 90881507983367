import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Joi from 'joi-browser';
import http from '../services/httpService';
import config from '../services/config.js';
import cookies from 'react-cookies';

class Edit extends Component {
	state = {};
	constructor(props) {
		super(props);
		this.state = {
			name: {
				eng_word: '',
				meaning: '',
				eng_desc: '',
				gender: '',
				arb_desc: '',
				syr_word: '',
				arb_word: '',
				variations: '',
				src: '',
				status: '',
				src_id: ''
			},
			cat_arr: [],
			errors: {},
			message: '',
			done: false,
			user: { level: 'admin' }
		};
	}
	componentDidMount() {
		this.serverRequestName(this.props.match.params.id);
	}
	serverRequestName = (id) => {
		http.get(config.API_ENDPOINT + '/names?id=' + id).then((result) => {
			if (result.status === 200 && result.data.results.length !== 0) {
				http.get(config.API_ENDPOINT + '/names/categories/' + id).then((cat_result) => {
					var incoming_arr = cat_result.data.results;
					var cat_arr = [];
					for (let i = 0; i < incoming_arr.length; i++) {
						cat_arr.push(incoming_arr[i].id);
					}
					const {
						eng_desc,
						eng_word,
						syr_word,
						arb_desc,
						arb_word,
						variations,
						src,
						meaning,
						status,
						gender,
						src_id
					} = result.data.results[0];
					console.log('get src_id', src_id);
					const edit_name = {
						eng_desc,
						eng_word,
						syr_word,
						arb_desc,
						arb_word,
						variations,
						src,
						meaning,
						status,
						gender,
						src_id
					};
					this.setState({ name: edit_name, cat_arr, user: this.props.user });
				});
			}
		});
	};
	schema = {
		eng_word: Joi.string().min(2).max(25).required().label('Name in English'),
		// syr_word: Joi.string().min(3).max(25).required().label('Name in Syriac'),
		// arb_word: Joi.string().min(3).max(25).required().label('Name in Arabic'),
		meaning: Joi.string().max(500).required().label('Meaning'),
		eng_desc: Joi.string().max(500).allow('').label('Description in English'),
		// arb_desc: Joi.string().max(500).allow('').label('Description in Arabic'),
		// src: Joi.string().max(200).allow('').label('Source'),
		// variations: Joi.string().max(200).allow('').label('Variations'),
		gender: Joi.string().required().label('Gender'),
		status: Joi.string().required().label('Status')
	};
	categories = [
		{ id: 10, cat: 'historical' },
		{ id: 11, cat: 'traditional' },
		{ id: 12, cat: 'poetic' },
		{ id: 13, cat: 'compound' },
		{ id: 14, cat: 'nature' },
		{ id: 15, cat: 'religious' },
		// { id: 16, cat: "non_syriac" },
		{ id: 17, cat: 'short' }
	];
	statuses = [ { id: 1, status: 'approved' }, { id: 2, status: 'pending' } ];
	handleFieldChange = (e) => {
		const name = { ...this.state.name };
		const type = e.target.id;
		let value = e.target.value;
		if (type === 'src_id') {
			value = parseInt(value);
		}
		name[type] = value;
		console.log(typeof value);
		this.setState({ name });
	};
	handleGenderChange = (e) => {
		const name = { ...this.state.name };
		name.gender = e.target.value;
		this.setState({ name });
	};
	validate = () => {
		const name = {
			// syr_word: this.state.name.syr_word,
			eng_word: this.state.name.eng_word,
			eng_desc: this.state.name.eng_desc,
			// arb_word: this.state.name.arb_word,
			// arb_desc: this.state.name.arb_desc,
			gender: this.state.name.gender,
			// src: this.state.name.src,
			// variations: this.state.name.variations,
			meaning: this.state.name.meaning,
			status: this.state.name.status
		};
		const result = Joi.validate(name, this.schema, { abortEarly: false });
		if (!result.error) return null;

		const errors = {};
		for (let error of result.error.details) {
			errors[error.path[0]] = error.message;
		}
		return errors;
	};
	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	handleSubmit = (e) => {
		// console.log('handling Submit....:', this.state.name.eng_word);
		this.scrollToTop();
		e.preventDefault();
		const cat_arr = [ ...this.state.cat_arr ];
		const errors = this.validate();
		if (!errors) {
			this.setState({ errors: {} });
			var name = {
				...this.state.name
			};
			console.log('src_id:', name.src_id);
			http
				.put(
					config.API_ENDPOINT + '/names/' + this.props.match.params.id,
					{ name, cat_arr },
					{ headers: { authorization: 'Bearer ' + cookies.load('token') } }
				)
				.then((result) => {
					if (result.status === 200) {
						this.setState({ done: true });
					} else {
						this.setState({ message: 'Error Updating The Name' });
					}
				});
		} else {
			this.setState({ errors });
		}
	};
	handleCatChange = (e) => {
		var cat_arr = this.state.cat_arr;
		const value = e.target.value;
		if (this.state.cat_arr.includes(value)) {
			cat_arr = this.state.cat_arr.filter((cat) => cat != value);
		} else {
			cat_arr.push(value);
		}
		this.setState({ cat_arr });
	};
	handleStatusChange = (e) => {
		var name = { ...this.state.name };
		name.status = e.target.value;
		this.setState({ name });
	};
	handleExitMessage = () => {
		this.setState({ errors: {}, message: {} });
	};
	handleDelete = () => {
		var name_id = this.props.match.params.id;

		http
			.delete(config.API_ENDPOINT + '/names/' + name_id, {
				headers: { authorization: 'Bearer ' + cookies.load('token') }
			})
			.then((response) => {
				this.setState({ done: true });
				this.scrollToTop();
			});
	};
	categories_list = () => {
		return this.categories.map((category) => (
			<div key={category.id} className="custom-control custom-switch">
				<input
					type="checkbox"
					className="custom-control-input"
					id={category.id}
					value={category.id || 'adsf'}
					checked={this.state.cat_arr.find((cat) => cat === category.id)}
					onChange={this.handleCatChange}
				/>
				<label className="custom-control-label" htmlFor={category.id}>
					{category.cat}
				</label>
			</div>
		));
	};
	statuses_list = () => {
		return this.statuses.map((status) => (
			<div key={status.id} className="custom-control custom-radio text-info">
				<input
					type="radio"
					id={status.id}
					name="customRadio"
					className="custom-control-input"
					value={status.status}
					checked={this.state.name.status === status.status}
					onChange={this.handleStatusChange}
				/>
				<label className="custom-control-label" htmlFor={status.id}>
					{status.status}
				</label>
			</div>
		));
	};
	render() {
		if (!this.props.isLoggedIn || this.props.user.level !== 'admin' || this.state.done) {
			return <Redirect to="/main" />;
		} else {
			return (
				<div className="edit-wrapper">
					<div className="edit-container">
						<div className="e-col">
							<p style={{ textAlign: 'center' }}>EDITING</p>
							{this.state.message.length > 0 && (
								<div className="message-container" onClick={this.handleExitMessage}>
									(X) {this.state.message}
								</div>
							)}

							<div className="input-container">
								<p>Name (English)</p>
								<input
									type="text"
									className="single-input"
									id="eng_word"
									placeholder="Name in English..."
									onChange={this.handleFieldChange}
									value={this.state.name.eng_word || ''}
								/>
							</div>
							{this.state.errors.eng_word && (
								<div className="message-container" onClick={this.handleExitMessage}>
									(X) {this.state.errors.eng_word}
								</div>
							)}
							<div className="input-container">
								<p>Name (Syriac)</p>
								<input
									type="text"
									className="single-input"
									id="syr_word"
									placeholder="Name in Syriac..."
									onChange={this.handleFieldChange}
									value={this.state.name.syr_word || ''}
								/>
							</div>
							{this.state.errors.syr_word && (
								<div className="message-container" onClick={this.handleExitMessage}>
									(X) {this.state.errors.syr_word}
								</div>
							)}

							<div className="input-container">
								<p>Name (Arabic)</p>
								<input
									type="text"
									className="single-input"
									id="arb_word"
									placeholder="Name in Arabic..."
									onChange={this.handleFieldChange}
									value={this.state.name.arb_word || ''}
								/>
							</div>
							{this.state.errors.arb_word && (
								<div className="message-container" onClick={this.handleExitMessage}>
									(X) {this.state.errors.arb_word}
								</div>
							)}
							<div className="input-container">
								<p>Meaning</p>
								<input
									type="text"
									className="single-input"
									id="meaning"
									placeholder="Meaning..."
									onChange={this.handleFieldChange}
									value={this.state.name.meaning || ''}
								/>
							</div>
							{this.state.errors.meaning && (
								<div className="message-container" onClick={this.handleExitMessage}>
									(X) {this.state.errors.meaning}
								</div>
							)}
							<div className="input-container">
								<p>Variations</p>
								<input
									type="text"
									className="single-input"
									id="variations"
									placeholder="Variations..."
									onChange={this.handleFieldChange}
									value={this.state.name.variations || ''}
								/>
							</div>
							{this.state.errors.variations && (
								<div className="message-container" onClick={this.handleExitMessage}>
									(X) {this.state.errors.variations}
								</div>
							)}
							<div className="input-container">
								<p>Description (English)</p>
								<textarea
									className="single-input"
									id="eng_desc"
									rows="3"
									placeholder="Add more description in English"
									onChange={this.handleFieldChange}
									value={this.state.name.eng_desc || ''}
								/>
							</div>
							{this.state.errors.eng_desc && (
								<div className="message-container" onClick={this.handleExitMessage}>
									(X) {this.state.errors.eng_desc}
								</div>
							)}
						</div>
						<div className="e-col">
							<div className="input-container">
								<p>Description (Arabic)</p>
								<textarea
									className="single-input"
									id="arb_desc"
									rows="3"
									placeholder="Add more description in Arabic"
									onChange={this.handleFieldChange}
									value={this.state.name.arb_desc || ''}
								/>
							</div>
							{this.state.errors.arb_desc && (
								<div className="message-container" onClick={this.handleExitMessage}>
									(X) {this.state.errors.arb_desc}
								</div>
							)}

							<div className="input-container">
								<p>Source</p>
								<input
									type="text"
									className="single-input"
									id="src"
									placeholder="Source..."
									onChange={this.handleFieldChange}
									value={this.state.name.src || ''}
								/>
								<select
									className="form-control"
									id="src_id"
									onChange={this.handleFieldChange}
									value={this.state.name.src_id}
								>
									<option value={0} disabled>
										Select One
									</option>
									<option value={1}>Names from the Assyrian Syriac Tradition</option>
									<option value={2}>Spring of Names</option>
									<option value={3}>BET KANU Team</option>
								</select>
							</div>
							{this.state.errors.src && (
								<div className="message-container" onClick={this.handleExitMessage}>
									(X) {this.state.errors.src}
								</div>
							)}

							<div className="input-container">
								<p>Gender</p>
								<select
									className="form-control"
									id="gender"
									onChange={this.handleGenderChange}
									value={this.state.name.gender}
								>
									<option value="select_one" disabled>
										Select One
									</option>
									<option value="male">Male</option>
									<option value="female">Female</option>
									<option value="neutral">Neutral</option>
								</select>
							</div>
							{this.state.errors.gender && (
								<div className="message-container" onClick={this.handleExitMessage}>
									(X) {this.state.errors.gender}
								</div>
							)}
							<div className="" style={{ textAlign: 'left' }}>
								<div className="edit-col">{this.categories_list()}</div>
								<div className="edit-col">{this.statuses_list()}</div>
								{this.state.errors.status && (
									<div className="message-container" onClick={this.handleExitMessage}>
										(X) {this.state.errors.status}
									</div>
								)}
							</div>
						</div>
					</div>

					<div className=" edit-button-container">
						<button className="add-btn" onClick={this.handleSubmit}>
							Submit
						</button>
						<Link to="/main">
							<button className="add-btn" onClick={this.scrollToTop}>
								Cancel
							</button>
						</Link>
						<button className="add-btn" onClick={this.handleDelete}>
							Delete this Name
						</button>
					</div>
				</div>
			);
		}
	}
}

export default Edit;
