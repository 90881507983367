import React from 'react';
import photo_1 from '../Photos/photo_1.jpg';
import photo_2 from '../Photos/photo_2.jpg';
import { Link } from 'react-router-dom';
// import photo_3 from '../Photos/photo_3.jpg';
// import photo_4 from '../Photos/photo_4.jpg';
import photo_5 from '../Photos/photo_5.jpg';
import photo_6 from '../Photos/photo_6.jpg';
// import photo_7 from '../Photos/photo_7.jpg';
import photo_8 from '../Photos/photo_8.png';
import photo_9 from '../Photos/photo_9.png';
import photo_10 from '../Photos/photo_10.png';
import photo_11 from '../Photos/photo_11.png';
import { initGA, capturePageview, captureEvent } from '../Analytics/googleAnalytics';
import { FacebookShareButton, FacebookIcon, TwitterIcon, TwitterShareButton } from 'react-share';
import logoNames from '../New_Icons/Bet_Kanu_Names_logo_4.png';

// import '../App.css';

const Support = (props) => {
	//   initGA();
	capturePageview();
	props.onScrollToTop();
	return (
		<React.Fragment>
			<div className="support">
				<div className="title">SUPPORT</div>
				{/* <div className="sub-title">Support Us Preserve Our Language</div> */}
				<div className="container">
					<div className="s-col">
						BET KANU Inc is a non-profit organization, and to continue with our mission we depend soley on
						support from people like you. In addition to publishing amazing products, we provide job
						opportunities to dozens of our talented youth back home.<br />
						<br />
						Help us in our mission to making Syriac ready for the digital age.
					</div>
					<div
						className="s-col"
						style={{ textAlign: 'center' }}
						onClick={() => captureEvent('Donation', 'Clicked Donate', "From 'support' page")}
					>
						{/* <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
							<input type="hidden" name="cmd" value="_s-xclick" />
							<input type="hidden" name="hosted_button_id" value="PHDWB4HCNLS5E" />
							<input
								type="image"
								src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
								border="0"
								name="submit"
								title="PayPal - The safer, easier way to pay online!"
								alt="Donate with PayPal button"
							/>
							<img
								alt=""
								border="0"
								src="https://www.paypal.com/en_US/i/scr/pixel.gif"
								width="1"
								height="1"
							/>
						</form> */}
						<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
							<input type="hidden" name="cmd" value="_s-xclick" />
							<input type="hidden" name="hosted_button_id" value="B2LWBB7C4J29G" />
							<input
								type="image"
								src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
								border="0"
								name="submit"
								title="PayPal - The safer, easier way to pay online!"
								alt="Donate with PayPal button"
							/>
							<img
								alt=""
								border="0"
								src="https://www.paypal.com/en_US/i/scr/pixel.gif"
								width="1"
								height="1"
							/>
						</form>
						<div style={{ marginTop: 20 }}>
							<span>Share: </span>
							<FacebookShareButton
								url={
									'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=B2LWBB7C4J29G&source=url'
								}
								quote={
									'Support our language by donating to BET KANU,a non-profit aiming to prepare #Syriac for the digital age.'
								}
								hashtag={'#BETKANU'}
							>
								<FacebookIcon size={30} />
							</FacebookShareButton>
							<TwitterShareButton
								style={{ marginLeft: 20, marginRight: 20 }}
								url={
									'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=B2LWBB7C4J29G&source=url'
								}
								title={
									'Support our language by donating to BET KANU,a non-profit aiming to prepare #Syriac for the digital age.'
								}
								hashtags={[ 'BETKANUNames', 'BETKANU' ]}
							>
								<TwitterIcon size={30} />
							</TwitterShareButton>
						</div>
					</div>
				</div>
				<div className="separator" />
				<div className="title">OUR WORK</div>
				<div className="container">
					<div className="s-col">
						<img src={photo_1} alt="photo 1" className="picture" />
					</div>
					<div className="s-col"> Comic books and mobile games</div>
				</div>
				<div className="container">
					{/* <div className="s-col"> ads;fjdafjads;lf jasd;lkfj dsalf</div> */}
					<div className="s-col">
						<div className="s-col">
							<img src={photo_2} alt="photo 2" className="picture" />
						</div>
					</div>
				</div>
				<div className="container">
					<div className="s-col">
						<img src={photo_8} alt="photo 8" className="picture" />
					</div>
					<div className="s-col">
						{' '}
						Animated songs. Subscribe to our <Link to="/youtubechannel">YouTube Channel</Link>
					</div>
				</div>
				<div className="container">
					<div className="s-col"> Designated studio for song recording</div>
					<div className="s-col">
						<div className="s-col">
							<img src={photo_6} alt="photo 6" className="picture" />
						</div>
					</div>
				</div>
				<div className="container">
					<div className="s-col">
						<img src={photo_9} alt="photo 9" className="picture" />
					</div>
					<div className="s-col">
						Convertng international lullibies to Syriac, like
						<Link to="/familyfinger"> "Family Finger"</Link>
					</div>
				</div>
				<div className="container">
					<div className="s-col">Integrating in our schools in the homeland</div>
					<div className="s-col">
						<div className="s-col">
							<img src={photo_5} alt="photo 5" className="picture" />
						</div>
					</div>
				</div>
				<div className="container">
					<div className="s-col">
						<img src={photo_10} alt="photo 10" className="picture" />
					</div>
					<div className="s-col">
						Embracing our culture and religion. Check out our <Link to="/jesussong">"Jesus" song</Link>
					</div>
				</div>
				<div className="container">
					<div className="s-col">
						Watch our original series "Nino & MIa" <Link to="/ninomia">on YouTube</Link>
					</div>
					<div className="s-col">
						<div className="s-col">
							<img src={photo_11} alt="photo 11" className="picture" />
						</div>
					</div>
				</div>
				<div className="sub-title" onClick={props.onScrollToTop}>
					Go to top ^
				</div>
			</div>
		</React.Fragment>
	);
};

export default Support;
