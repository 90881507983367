import axios from 'axios';
import config from '../services/config';
const dotenv = require('dotenv');
dotenv.config();
// axios.options({ headers: { 'x-api-key': process.env.NAMES_API_KEY } });
axios.defaults.headers = {
	'x-api-key': config.API_KEY
};
export default {
	get: axios.get,
	post: axios.post,
	delete: axios.delete,
	put: axios.put
};
