import React from 'react';
import './SideDrawer.css';
import { Link } from 'react-router-dom';
import logo from '../../New_Icons/Dargushto_Logo_header.png';
import logoNames from '../../New_Icons/Bet_Kanu_Names_logo_4.png';

import '../../App.css';
import { initGA, capturePageview, captureEvent } from '../../Analytics/googleAnalytics';
import { FacebookShareButton, FacebookIcon, TwitterIcon, TwitterShareButton } from 'react-share';
// const ROBOTO = 'Roboto Font';

const SideDrawer = (props) => {
	let drawerClasses = 'side-drawer';
	if (props.show) {
		drawerClasses = 'side-drawer open';
	}
	return (
		<React.Fragment>
			<nav>
				<ul className={drawerClasses}>
					<li>
						<Link to="/main">
							<img
								src={logoNames}
								alt="logo"
								className="side-drawer-logo"
								onClick={props.drawerClickHandler}
							/>
						</Link>
					</li>
					<li />
					{props.isLoggedIn ? (
						<li
							onClick={() => {
								captureEvent('Navigation', 'Clicked Profile Logo', 'From SideMenu');
								props.drawerClickHandler();
							}}
						>
							<Link to="/favorite">
								<img className="photo" src={props.user.image} alt="profile pic" />
							</Link>

							<span
								style={{
									fontSize: '18pt',
									color: 'white',
									fontWeight: 'normal',
									marginLeft: '10px',
									marginTop: '15px'
								}}
							>
								{props.user.first_name}
							</span>
						</li>
					) : (
						<li
							onClick={() => {
								captureEvent('Auth', 'Clicked Login', 'From SideMenu');
								props.drawerClickHandler();
							}}
						>
							<Link to="/login" className="menu-item">
								Login
							</Link>
						</li>
					)}

					<li
						onClick={() => {
							captureEvent('Favorites', 'Clicked Cart', 'From SideMenu');
							props.drawerClickHandler();
						}}
					>
						<Link to="/favorite" className="menu-item">
							Cart {props.count}
						</Link>
					</li>

					<li>
						<Link
							to="/main"
							className="menu-item"
							onClick={() => {
								captureEvent('Navigation', 'Clicked Home Tab', 'From SideMenu');
								props.drawerClickHandler();
							}}
						>
							Home
						</Link>
					</li>
					<li>
						<Link
							to="/about"
							className="menu-item"
							onClick={() => {
								captureEvent('Navigation', 'Clicked About Tab', 'From SideMenu');
								props.drawerClickHandler();
							}}
						>
							About
						</Link>
					</li>
					<li>
						<Link
							to="/support"
							className="menu-item"
							onClick={() => {
								captureEvent('Navigation', 'Clicked Support Tab', 'From SideMenu');
								props.drawerClickHandler();
							}}
						>
							Support
						</Link>
					</li>
					<li>
						<Link
							to="/contact"
							className="menu-item"
							onClick={() => {
								captureEvent('Navigation', 'Clicked contact Tab', 'From SideMenu');
								props.drawerClickHandler();
							}}
						>
							Contact
						</Link>
					</li>
					{props.isLoggedIn ? (
						<li onClick={props.drawerClickHandler}>
							<Link
								to="/main"
								className="menu-item"
								onClick={() => {
									captureEvent('Auth', 'Clicked Logout', 'From SideMenu');
									props.drawerClickHandler();
									props.onLogout();
								}}
							>
								Logout
							</Link>
						</li>
					) : null}
					<li />
					<li>
						<FacebookShareButton
							style={{ marginLeft: 10 }}
							url={'https://names.betkanu.com'}
							quote={
								'This is the best website to find Syriac names for your babies. Different filter options, fonts, and much more. '
							}
							hashtag={'#BETKANUNames'}
						>
							<FacebookIcon size={40} />
						</FacebookShareButton>
						<TwitterShareButton
							style={{ marginLeft: 20 }}
							url={'https://names.betkanu.com'}
							title={'BET KANU Names: the best website to find #Syriac names for your babies'}
							hashtags={[ 'BETKANUNames', 'BETKANU' ]}
						>
							<TwitterIcon size={40} />
						</TwitterShareButton>
					</li>
					{/* <li style={{ color: "rgba(0,0,0,0)" }}>Transparent</li> */}
				</ul>
				{/* </div> */}
			</nav>
		</React.Fragment>
	);
};

export default SideDrawer;
