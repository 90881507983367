import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import add_icon from "../Add_Icon.png"
import add_icon from '../New_Icons/Add_Jessie.png';
import { initGA, capturePageview, captureEvent } from '../Analytics/googleAnalytics';
import http from '../services/httpService';
import config from '../services/config.js';
import loading_gif from '../New_Icons/loading_2.gif';
import Joi from 'joi-browser';
import { FacebookShareButton, FacebookIcon, TwitterIcon, TwitterShareButton } from 'react-share';
import BKLogo from '../BetkanuLogo_small.png';
import Lottie from 'react-lottie';
import Add from '../New_Icons/Add.json';
class Footer extends Component {
	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	state = {
		email: '',
		message: '',
		loading: false,
		errors: {}
	};
	handleEmailChange = (e) => {
		this.setState({ email: e.target.value });
	};
	schema = {
		email: Joi.string().min(3).max(100).required().email().label('Email')
	};
	validate = () => {
		const result = Joi.validate({ email: this.state.email }, this.schema);
		if (!result.error) return null;

		const errors = {};
		for (let error of result.error.details) {
			errors[error.path[0]] = error.message;
		}
		return errors;
	};
	handleSubmitEmail = () => {
		captureEvent('Subscribing to Newsletter', 'Clicked subscribe', 'From Footer');
		this.setState({ loading: true });
		const errors = this.validate();
		if (errors) {
			this.setState({ errors, loading: false });
		} else {
			const data = { email: this.state.email };
			http.post(config.API_ENDPOINT + '/subscribe', data).then((response) => {
				let message = '';
				response.data.result
					? (message = 'Thank you for Subscribing')
					: (message = 'Error: Something wrong happened. Please check back in a moment!');
				this.setState({ message, loading: false, errors: {}, email: '' });
			});
		}
	};
	handleExitMessage = () => {
		this.setState({ errors: {}, message: '' });
	};
	render() {
		return (
			<React.Fragment>
				<div className="footer-top-container">
					<div className="footer-half-container">
						<div className="footer-header">You know a name you want us to add?</div>
						<div className="footer-body">
							<p className="">Our team will be more than happy to consider it for addition. Thank you!</p>
							{/* <Link to={{ pathname: '/add', state: { from: '/sargon' } }}>
								<button
									className="add-btn"
									onClick={() => {
										captureEvent('Adding Name', 'Clicked Add Name', 'From Footer');
										this.scrollToTop();
									}}
									id="addname_step"
								>
									<img src={add_icon} alt="add icon" height="25px" /> Add a Name
								</button>
							</Link> */}

							<Link
								to="/add"
								onClick={() => {
									captureEvent(
										'Adding Name',
										'Clicked Add Name',
										'From Main Page (no names available)'
									);
									this.scrollToTop();
								}}
							>
								<button
									style={{ height: '40px' }}
									className="add-btn"
									onClick={() => {
										captureEvent('Adding Name', 'Clicked Add Name', 'From Footer');
										this.scrollToTop();
									}}
									id="addname_step"
								>
									{/* <img src={add_icon} alt="add icon" height="30px" /> Add a Name */}
									<Lottie
										options={{
											loop: true,
											animationData: Add
										}}
										isClickToPauseDisabled={true}
										height={30}
										width={30}
										style={{
											// backgroundColor: 'red',
											display: 'inline-block',
											verticalAlign: 'top'
										}}
									/>{' '}
									<div style={{ paddingTop: 3, display: 'inline-block' }}>Add a Name</div>
								</button>
							</Link>
						</div>
					</div>
					<div className="footer-half-container">
						<div className="footer-header">Subscribe to our Monthly Newsletter:</div>
						<div className="subscribe-container">
							{this.state.errors.email ? (
								<div className="message-container" onClick={this.handleExitMessage}>
									{this.state.errors.email} (X)
									{/* <img src={cancel_info_icon} alt="cancel icon" height="20px" onClick={this.handleExitMessage} /> */}
								</div>
							) : null}
							{this.state.message.length > 0 ? (
								<div className="message-container" onClick={this.handleExitMessage}>
									{this.state.message} (X)
									{/* <img src={cancel_info_icon} alt="cancel icon" height="20px" onClick={this.handleExitMessage} /> */}
								</div>
							) : null}
							<input
								type="text"
								className="single-input"
								id="email"
								placeholder="email..."
								onChange={this.handleEmailChange}
								value={this.state.email || ''}
							/>
							<div style={{ marginTop: 10 }}>
								<button className="add-btn" onClick={this.handleSubmitEmail} id="addname_step">
									Subscribe
								</button>
								{this.state.loading ? <img src={loading_gif} alt="loading.." height="50px" /> : null}
							</div>
						</div>
						<div style={{ marginTop: 20 }}>
							<span>Share: </span>
							<FacebookShareButton
								url={'https://names.betkanu.com'}
								quote={
									'This is the best website to find Syriac names for your babies. Different filter options, fonts, and much more. '
								}
								hashtag={'#BETKANUNames'}
							>
								<FacebookIcon size={30} />
							</FacebookShareButton>
							<TwitterShareButton
								style={{ marginLeft: 20, marginRight: 20 }}
								url={'https://names.betkanu.com'}
								title={'BET KANU Names: the best website to find #Syriac names for your babies'}
								hashtags={[ 'BETKANUNames', 'BETKANU' ]}
							>
								<TwitterIcon size={30} />
							</TwitterShareButton>
						</div>
					</div>
				</div>
				<div className="footer-container">
					<div className="footer-header line">Who we are</div>
					<div className="footer-body">
						<p className="">
							<a href="http://www.betkanu.com" style={{ color: 'white', textDecoration: 'none' }}>
								<img src={BKLogo} height={20} />
								BET KANU
							</a>{' '}
							is a team of creatives with a mission to build a foundation to advance the endangered
							dialects of the Syriac language. We achieve this goal by creating all types of media
							products such as web and mobile applications, websites, animations and books. Our full-time
							team of talented professionals in the homeland works seamlessly with the supporting
							volunteers in the diaspora to offer the best possible learning resources.
						</p>
					</div>
					<small>&copy; 2020 BET KANU. All Rights Reserved.</small>
				</div>
			</React.Fragment>
		);
	}
}

export default Footer;
