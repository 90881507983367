import React, { Component } from "react";

class Callback extends Component {
  state = {};
  render() {
    return <h1>Call Back</h1>;
  }
}

export default Callback;
