import React, { Component } from 'react';
import Filters from './Filters';
import NamesContainer from './NamesContainer';
import http from '../services/httpService';
import config from '../services/config.js';
import FilterHeader from './FilterHeader';
import Search from './Search';
import cookies from 'react-cookies';
import logo from '../New_Icons/Dargushto_Logo_header.png';
import logoNames from '../New_Icons/Bet_Kanu_Names_logo_4.png';
import { Link } from 'react-router-dom';
import Tour from 'reactour';
import steps from './TourSteps/steps';
import { initGA, capturePageview, captureEvent } from '../Analytics/googleAnalytics';
// import jwt from "jsonwebtoken";
// To set max
var sixMonths = new Date();
sixMonths.setMonth(sixMonths.getMonth() + 6);

class Main extends Component {
	state = {};

	constructor(props) {
		super(props);

		if (!cookies.load('isTourDone')) {
			cookies.save('isTourDone', false, { path: '/', expires: sixMonths });
		}
		this.state = {
			fave_list: this.props.fave_list,
			names: [],
			loading: true,
			fitlerToggled: true,
			filtersChosen: false,
			currPage: 1,
			namesCount: 'no',
			startPage: 1,
			offset: '',
			dialect: 'Eastern',
			filters: {
				id: 0,
				gender: '',
				status: 'approved',
				limit: 15,
				searchWord: '',
				sort_by: 'asc.syr_word',
				categories: {
					traditional: false,
					religious: false,
					historical: false,
					creative: false,
					poetic: false,
					non_syriac: false,
					short: false,
					nature: false
				}
			},
			steps,
			isTourOpen: !(cookies.load('isTourDone') === 'true')
		};
	}
	componentDidMount() {}

	componentDidUpdate(prevProps, prevState) {
		var gender = this.state.filters.gender;
		const currFilters = { ...this.state.filters };
		//Checking for GENDER Change
		if (prevState && prevState.filters.gender !== gender) {
			this.serverRequest();
		}

		//checking for Offset update
		if (prevState && this.state.offset !== prevState.offset) {
			this.serverRequest();
		}

		//checking SearchWord update
		if (prevState && prevState.filters.searchWord !== currFilters.searchWord) {
			if (this.state.loading) {
				return;
			}
			this.serverRequest();
		}

		//checking for SORTING update
		if (prevState && prevState.filters.sort_by !== currFilters.sort_by) {
			this.serverRequest();
		}
		//check categories RESET update
		for (let cat in currFilters.categories) {
			if (currFilters.categories[cat] !== prevState.filters.categories[cat]) {
				this.serverRequest();
			}
		}
		//check for status change
		if (prevState && prevState.filters.status !== currFilters.status) {
			this.serverRequest();
		}
	}
	serverRequest = async () => {
		var query = this.buildQuery();
		var countQuery = this.buildQuery_WithoutPagination();
		this.setState({
			loading: true
		});
		const namesResponse = await http.get(config.API_ENDPOINT + query);
		const namesCountResponse = await http.get(config.API_ENDPOINT + countQuery);
		const names = namesResponse.data.results;
		// console.log('Names: ', names);
		this.setState({
			names,
			namesCount: namesCountResponse.data.results,
			loading: false
		});
		this.handleCheckReset();
	};

	handleGenderChange = async (gender) => {
		if (this.state.loading) {
			return;
		}
		var filtersCopy = { ...this.state.filters };

		filtersCopy.gender = gender;
		filtersCopy.searchWord = '';
		captureEvent('Filters', 'Toggled Gender', 'Gender: ' + gender);
		this.setState({
			filters: filtersCopy,
			currPage: 1,
			offset: 0,
			startPage: 1
		});
	};
	handleCatChange = async (cat) => {
		if (this.state.loading) {
			return;
		}
		var filtersCopy = { ...this.state.filters };
		filtersCopy.searchWord = '';
		for (var category in filtersCopy.categories) {
			if (category === cat) {
				captureEvent(
					'Filters',
					'Toggled Category',
					'Cat: ' + cat + ',' + filtersCopy.categories[cat] ? 'OFF' : 'ON'
				);
				filtersCopy.categories[cat] = !filtersCopy.categories[cat];
			}
		}
		this.setState({
			filters: filtersCopy,
			offset: 0,
			currPage: 1,
			startPage: 1
		});
		this.serverRequest();
	};
	buildQuery = () => {
		var filters = this.state.filters;
		var query = `/names?status=${filters.status}&`;

		if (filters.gender !== '') {
			query += `gender=${filters.gender}&`;
		}

		for (var cat in filters.categories) {
			if (filters.categories[cat] === true) {
				query += `cat=${cat}&`;
			}
		}

		if (filters.id > 0) {
			query += `id=${filters.id}&`;
		}
		if (filters.searchWord !== '') {
			query += `word=${filters.searchWord}&`;
		}
		if (filters.limit !== '') {
			query += `limit=${filters.limit}&`;
		}
		if (this.state.offset !== '') {
			query += `offset=${this.state.offset}&`;
		}
		query += `sort_by=${filters.sort_by}`;

		return query;
	};
	buildQuery_WithoutPagination = () => {
		var filters = this.state.filters;
		var query = `/names/count?status=${filters.status}&`;
		// var query = `/names/count?`;

		if (filters.gender !== '') {
			query += `gender=${filters.gender}&`;
		}

		for (var cat in filters.categories) {
			if (filters.categories[cat] === true) {
				query += `cat=${cat}&`;
			}
		}

		if (filters.id > 0) {
			query += `id=${filters.id}&`;
		}
		if (filters.searchWord !== '') {
			query += `word=${filters.searchWord}&`;
		}
		query += `sort_by=${filters.sort_by}`;

		return query;
	};

	componentDidMount() {
		this.serverRequest();
	}
	handleReset = () => {
		// if (this.state.loading) {
		// 	return;
		// }
		captureEvent('Tools', 'Cleared Filters', 'N/A');
		var origFilters = {
			id: 0,
			gender: '',
			status: 'approved',
			limit: 15,
			searchWord: '',
			sort_by: 'asc.syr_word',
			categories: {
				traditional: false,
				religious: false,
				historical: false,
				creative: false,
				poetic: false,
				non_syriac: false,
				short: false,
				nature: false
			}
		};
		this.setState({
			filters: origFilters,
			offset: 0,
			currPage: 1
		});
		// this.serverRequest();
	};
	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	handleTextChange = (e) => {
		var word = e.target.value;

		for (let i = 0; i < word.length; i++) {
			let code = word.charCodeAt(i);
			// if Syriac
			if (code >= 1792 && code <= 1871) {
				//if Arabic
			} else if (code >= 1536 && code <= 1791) {
			} else {
			}
		}
		var filters = { ...this.state.filters };
		filters.searchWord = e.target.value.trim();
		this.setState({ filters, offset: 0, startPage: 1 });
	};
	handleSelectPage = (pageNumber) => {
		this.scrollToTop();
		const offset = (pageNumber - 1) * this.state.filters.limit;
		this.setState({ currPage: pageNumber, offset });
		// this.serverRequest();
	};
	handlePrev = (pageNumber) => {
		this.scrollToTop();
		const offset = (pageNumber + 3) * this.state.filters.limit;
		this.setState({ currPage: pageNumber + 4, offset, startPage: pageNumber });
	};
	handleNext = (pageNumber) => {
		this.scrollToTop();
		const offset = (pageNumber - 1) * this.state.filters.limit;
		this.setState({ currPage: pageNumber, offset, startPage: pageNumber });
	};
	handleSort = (word) => {
		captureEvent('UX', 'Clicked Sort', 'Sort By: ' + word);
		const filters = { ...this.state.filters };
		var currSort_by = filters.sort_by;

		var direction = currSort_by.slice(0, currSort_by.indexOf('.'));

		if (direction === 'asc') {
			filters.sort_by = 'desc.' + word;
		} else {
			filters.sort_by = 'asc.' + word;
		}
		this.setState({ filters, currPage: 1, offset: 0, startPage: 1 });
	};
	handleToggleFilters = () => {
		captureEvent('UX', 'Toggled Filters', this.state.filterToggled ? 'OFF' : 'ON');
		this.setState({ fitlerToggled: !this.state.fitlerToggled });
	};
	handleCheckReset = () => {
		const filters = { ...this.state.filters };
		var filters_chosen = false;
		for (let cat in filters.categories) {
			if (filters.categories[cat]) {
				filters_chosen = true;
			}
		}
		if (filters.searchWord !== '' || filters.gender !== '') {
			filters_chosen = true;
		}
		if (filters.status !== 'approved') {
			filters_chosen = true;
		}
		this.setState({ filtersChosen: filters_chosen });
	};
	handleDelete = (name_id) => {
		http
			.delete(config.API_ENDPOINT + '/names/' + name_id, {
				headers: { authorization: 'Bearer ' + cookies.load('token') }
			})
			.then((result) => {
				this.serverRequest();
			});
	};
	handleStatusChange = (status) => {
		var filters = { ...this.state.filters };
		filters.status = status;
		this.setState({ filters, startPage: 1 });
	};
	handleEdit = (name_id) => {};
	handleCloseTour = () => {
		this.setState({ isTourOpen: false });
		cookies.save('isTourDone', true, { path: '/', expires: sixMonths });
	};
	render() {
		const { steps, isTourOpen } = this.state;
		return (
			<React.Fragment>
				<div className="header-container">
					<FilterHeader onToggleFilters={this.handleToggleFilters} filterToggled={this.state.fitlerToggled}>
						FilterHeader
					</FilterHeader>
					<Search onTextChange={this.handleTextChange} word={this.state.filters.searchWord}>
						Search
					</Search>
				</div>
				<Tour
					steps={steps}
					isOpen={isTourOpen}
					onRequestClose={this.handleCloseTour}
					styles={{ color: 'red' }}
					rounded={10}
					scrollDuration={500}
				/>
				<div className="main-container">
					<Filters
						onGenderChange={this.handleGenderChange}
						onCatChange={this.handleCatChange}
						onReset={this.handleReset}
						onTextChange={this.handleTextChange}
						filtersChosen={this.state.filtersChosen}
						categories={this.state.filters.categories}
						gender={this.state.filters.gender}
						word={this.state.filters.searchWord}
						onToggleFilters={this.handleToggleFilters}
						filterToggled={this.state.fitlerToggled}
						onStatusChange={this.handleStatusChange}
						status={this.state.filters.status}
						user={this.props.user}
					/>
					<NamesContainer
						names={this.state.names}
						namesCount={this.state.namesCount}
						currPage={this.state.currPage}
						pageSize={this.state.filters.limit}
						onSelectPage={this.handleSelectPage}
						onReset={this.handleReset}
						onSort={this.handleSort}
						sorting={this.state.filters.sort_by}
						onAddFavorite={this.props.onAddFavorite}
						onDelete={this.handleDelete}
						user={this.props.user}
						onEdit={this.handleEdit}
						fave_list={this.props.fave_list}
						isLoggedIn={this.state.isLoggedIn}
						onNext={this.handleNext}
						onPrev={this.handlePrev}
						startPage={this.state.startPage}
						dialect={this.props.dialect}
						onTestLogin={this.props.onTestLogin}
						loading={this.state.loading}
						onFeedbackChange={this.props.onFeedbackChange}
					/>
				</div>
			</React.Fragment>
		);
	}
}

export default Main;
