import React, { Component } from 'react';
// import cancel_info_icon from "../New_Icons/Cancel_Info_Jessie.png";
// import cancel_info_icon from "../New_Icons/SVG_Icons/cancle.svg";

import { Link } from 'react-router-dom';
// import edit_icon from "../Edit_Icon.png"
import edit_icon from '../New_Icons/SVG_Icons/edit.svg';
// import star_icon_empty from "../New_Icons/Star_Empty_Jessie.png"
// import star_icon_full from "../New_Icons/Star_Full_Jessie.png"
import star_icon_empty from '../New_Icons/SVG_Icons/star.svg';
import star_icon_full from '../New_Icons/SVG_Icons/star_filled.svg';

import '../App.css';

const SYRIAC_W = 'Serto Urhoy Font';
const SYRIAC_E = 'AA- East Syriac Marcus';

class NameBox extends Component {
	state = {};

	render() {
		return (
			<div className="name-box-big" onClick={() => this.props.onToggleInfo(this.props.name.id)}>
				<div className="n-row">
					<div className="n-col s-eng-col n">{this.props.name.eng_word}</div>
					<div
						className="n-col s-syr-col s"
						style={this.props.dialect === 'Eastern' ? { fontFamily: SYRIAC_E } : { fontFamily: SYRIAC_W }}
					>
						{this.props.onConvertDialect(this.props.name.syr_word, this.props.dialect)}
					</div>
					<div className="n-col arab-col s-arb-col">{this.props.name.arb_word}</div>
					<div className=" n-col n">
						<small>{this.props.name.meaning}</small>
					</div>

					<div className="s-icon-col">
						{this.props.user && this.props.user.level === 'admin' ? (
							<React.Fragment>
								<Link to={`/edit/${this.props.name.id}`} onClick={this.scrollToTop}>
									<img src={edit_icon} style={{ width: '28px' }} alt="Edit logo" />
								</Link>
							</React.Fragment>
						) : null}
						{this.props.fave_list.find((name) => name.id === this.props.name.id) ? (
							<img
								src={star_icon_full}
								style={{ width: '28px', marginLeft: '5px', cursor: 'pointer' }}
								alt="like logo"
								onClick={() => this.props.onAddFavorite(this.props.name.id)}
							/>
						) : (
							<img
								src={star_icon_empty}
								style={{ width: '28px', marginLeft: '5px', cursor: 'pointer' }}
								alt="like logo"
								onClick={() => this.props.onAddFavorite(this.props.name.id)}
							/>
						)}
					</div>
				</div>
				<div className="more-info-container" onClick={() => this.props.onToggleInfo(this.props.name.id)}>
					<div className="title text-info border-bottom" style={{ textAlign: 'center', marginTop: '10px' }} />
					<div className="info-table">
						<div className="i-row">
							<div className="i-col l">Gender:</div>
							<div className="i-col r">{this.props.name.gender}</div>
						</div>
						{this.props.name.variations !== '' ? (
							<div className="i-row">
								<div className="i-col l">Variations:</div>
								<div className="i-col r">{this.props.name.variations}</div>
							</div>
						) : null}
						{this.props.name.eng_desc !== '' ? (
							<div className="i-row">
								<div className="i-col l">Description:</div>
								<div className="i-col r">{this.props.name.eng_desc}</div>
							</div>
						) : null}
						{this.props.name.arb_word !== '' ? (
							<div className="i-row">
								<div className="i-col l a">الكلمة:</div>
								<div className="i-col r a">{this.props.name.arb_word}</div>
							</div>
						) : null}
						{this.props.name.arb_desc !== '' ? (
							<div className="i-row">
								<div className="i-col l">المعنى:</div>
								<div className="i-col r am">{this.props.name.arb_desc}</div>
							</div>
						) : null}

						{this.props.name.src !== '' ? (
							<div className="i-row">
								<div className="i-col l">Source2:</div>
								<div className="i-col r">{this.props.name.src}</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}

export default NameBox;
