import React, { Component } from 'react';
import photo_1 from '../Photos/photo_1.jpg';
import photo_2 from '../Photos/photo_2.jpg';
import { Redirect, Link } from 'react-router-dom';
// import photo_3 from '../Photos/photo_3.jpg';
// import photo_4 from '../Photos/photo_4.jpg';
import photo_5 from '../Photos/photo_5.jpg';
import photo_6 from '../Photos/photo_6.jpg';
// import photo_7 from '../Photos/photo_7.jpg';
import photo_8 from '../Photos/photo_8.png';
import photo_9 from '../Photos/photo_9.png';
import photo_10 from '../Photos/photo_10.png';
import photo_11 from '../Photos/photo_11.png';
import { initGA, capturePageview, captureEvent } from '../Analytics/googleAnalytics';
import http from '../services/httpService';
import config from '../services/config.js';
import Joi from 'joi-browser';
// import '../App.css';
import loading_gif from '../New_Icons/loading_2.gif';
import cookies from 'react-cookies';
import { FacebookShareButton, FacebookIcon, TwitterIcon, TwitterShareButton } from 'react-share';

class Contact extends Component {
	state = {};
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			errors: {},
			message: '',
			loading: false,
			subject: props.feedbackMessage || ''
		};
		capturePageview();
		this.scrollToTop();
	}
	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	schema = {
		email: Joi.string().min(3).max(100).required().email().label('Email'),
		subject: Joi.string().required().label('Subject')
	};
	handleEmailChange = (e) => {
		var email = e.target.value;
		this.setState({ email });
	};
	validate = () => {
		const result = Joi.validate({ email: this.state.email, subject: this.state.subject }, this.schema);
		if (!result.error) return null;

		const errors = {};
		for (let error of result.error.details) {
			errors[error.path[0]] = error.message;
		}
		return errors;
	};
	handleEmail = () => {
		captureEvent('Social', 'Clicked send Message', 'Button');
		const { isLoggedIn } = this.props;
		var fromEmail = isLoggedIn ? cookies.load('email') : this.state.email;

		if (!isLoggedIn) {
			var errors = this.validate();
			if (errors) {
				this.setState({ errors });
				return;
			}
		}
		var data = {
			fromEmail,
			subject: this.state.subject
		};
		http
			.post(config.API_ENDPOINT + '/email_contactus', data)
			.then((response) => {
				let message = '';
				response.data.result ? (message = 'Email Sent Successfully') : (message = 'Error Sending an Email!');
				this.setState({ message, loading: false, errors: {} });
				this.props.history.push('/main');
			})
			.catch((error) => {
				this.setState({ message: 'Error sending an email' });
			});
		this.setState({ loading: true });
	};
	handleDescChage = (e) => {
		const subject = e.target.value;
		this.setState({ subject });
	};
	handleExitMessage = () => {
		this.setState({ errors: {}, message: '' });
	};
	render() {
		const { isLoggedIn } = this.props;
		return (
			<div className="support">
				<div className="add-container">
					<div className="input-container">Contact us for any questions:</div>

					{this.state.errors.email ? (
						<div className="message-container" onClick={this.handleExitMessage}>
							{this.state.errors.email} (X)
						</div>
					) : null}
					{this.state.errors.subject ? (
						<div className="message-container" onClick={this.handleExitMessage}>
							{this.state.errors.subject} (X)
						</div>
					) : null}
					{this.state.loading ? <img src={loading_gif} alt="loading.." height="50px" /> : null}
					{isLoggedIn ? null : (
						<div className="input-container">
							<input
								className="long-input"
								name="emai"
								placeholder="Your Email"
								value={this.state.email}
								onChange={this.handleEmailChange}
							/>
						</div>
					)}

					<div className="input-container">
						<textarea
							className="long-input"
							placeholder="Message here..."
							onChange={this.handleDescChage}
							value={this.state.subject || ''}
						/>
					</div>

					<div className="input-container">
						<button className="add-btn" onClick={this.handleEmail}>
							Send
						</button>
						<Link
							to="/main"
							onClick={() => {
								captureEvent('Navigation', 'Cancelled Adding Name', 'Button');
								this.props.onFeedbackChange('');
							}}
						>
							<button
								className="add-btn"
								onClick={() => {
									this.scrollToTop();
									this.props.onFeedbackChange('');
								}}
							>
								Cancel
							</button>
						</Link>
					</div>

					<div style={{ marginTop: 20 }}>
						<span>Share: </span>
						<FacebookShareButton
							url={'https://names.betkanu.com'}
							quote={
								'This is the best website to find Syriac names for your babies. Different filter options, fonts, and much more. '
							}
							hashtag={'#BETKANUNames'}
						>
							<FacebookIcon size={30} />
						</FacebookShareButton>
						<TwitterShareButton
							style={{ marginLeft: 20, marginRight: 20 }}
							url={'https://names.betkanu.com'}
							title={'BET KAU Names: the best website to find #Syriac names for your babies'}
							hashtags={[ 'BETKANUNames', 'BETKANU' ]}
						>
							<TwitterIcon size={30} />
						</TwitterShareButton>
					</div>
				</div>
			</div>
		);
	}
}
export default Contact;
