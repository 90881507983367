import React from 'react';
// import search_icon from "../Search_Icon.png";
// import search_icon from "../New_Icons/Search_Jessie.png";
import search_icon from '../New_Icons/SVG_Icons/search.svg';
import { capturePageview, captureEvent } from '../Analytics/googleAnalytics';
const Search = (props) => {
	return (
		<div className="search-bar" id="search_step">
			<form onSubmit={(e) => e.preventDefault()}>
				<img src={search_icon} alt="Search Icon" style={{ height: '23px', marginRight: '10px' }} />
				<input
					className="search mr-sm-2"
					type="text"
					placeholder="Search Name or Meaning..."
					// height="60px"
					onChange={props.onTextChange}
					onClick={() => captureEvent('Filters', 'Search', 'N/A')}
					value={props.word || ''}
				/>
			</form>
		</div>
	);
};

export default Search;
