import React, { Component } from 'react';
import Pagination from './Pagination';
import NameBox from './NameBox';
import NameBoxBig from './NameBoxBig';
// import sort_icon_asc from "../New_Icons/Sort_Up_Jessie.png";
// import sort_icon_desc from "../New_Icons/Sort_Down_Jessie.png";
import sort_icon_asc from '../New_Icons/SVG_Icons/sort.svg';
import sort_icon_desc from '../New_Icons/SVG_Icons/sort_down.svg';
// import sort from "../New_Icons/SVG_Icons/sort.svg";
import clear_icon from '../New_Icons/Clear_Jessie.png';
import loading_gif from '../New_Icons/loading_2.gif';
import Searching from '../New_Icons/searching.json';
import Add from '../New_Icons/Add.json';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
// import add_icon from "../New_Icons/Add_Jessie.png"
import add_icon from '../New_Icons/SVG_Icons/add.svg';
import ReactGA from 'react-ga';
import { initGA, capturePageview, captureEvent } from '../Analytics/googleAnalytics';

// import west from '../New_Icons/SVG_Icons/west.svg';
// import east from '../New_Icons/SVG_Icons/east.svg'

class NamesContainer extends Component {
	state = {
		name_info_id: 0,
		isInfoToggled: false,
		expandingName: {
			isExpanded: false,
			id: -1
		}
	};
	handleToggleInfo = (info_id) => {
		captureEvent('UX', 'Toggled Expand', 'N/A');
		var { expandingName } = this.state;
		//if nothing is expanded, and it's different name
		if (!expandingName.isExpanded && info_id !== expandingName.id) {
			expandingName = {
				isExpanded: true,
				id: info_id
			};
			//if something is expanded, but it's a different name
		} else if (info_id !== expandingName.id) {
			expandingName = {
				isExpanded: true,
				id: info_id
			};
			//if something is expanded, and it's the same name
		} else {
			expandingName = {
				isExpanded: false,
				id: -1
			};
		}
		this.setState({
			expandingName
		});
	};
	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	handleConvertDialect = (word, dialect) => {
		if (dialect === 'Eastern') {
			return word
				.replace(/\u0730/g, '\u0732')
				.replace(/\u0733/g, '\u0735')
				.replace(/\u0736/g, '\u0739')
				.replace(/\u073A/g, '\u0738');
		} else {
			return word
				.replace(/\u0732/g, '\u0730')
				.replace(/\u0735/g, '\u0733')
				.replace(/\u0739/g, '\u0736')
				.replace(/\u0738/g, '\u073A');
		}
	};
	render() {
		return (
			<div className="names-container" id="result_step">
				{this.props.loading ? (
					<div className="no-names-container">
						<Lottie
							options={{
								loop: true,
								animationData: Searching
							}}
							isClickToPauseDisabled={true}
							height={200}
						/>
					</div>
				) : this.props.namesCount !== 0 ? (
					<React.Fragment>
						<div className="names-titles">
							<div className="n-row" style={{ marginTop: '20px' }}>
								<div className="n-col t" onClick={() => this.props.onSort('eng_word')}>
									<p style={{ cursor: 'pointer' }}>
										English
										{this.props.sorting === 'desc.eng_word' ? (
											<img src={sort_icon_desc} alt="sort icon" height="15px" />
										) : null}
										{this.props.sorting === 'asc.eng_word' ? (
											<img src={sort_icon_asc} alt="sort icon" height="15px" />
										) : null}
									</p>
								</div>
								<div className="n-col t" onClick={() => this.props.onSort('syr_word')}>
									<p style={{ cursor: 'pointer' }}>
										Syriac
										{this.props.sorting === 'desc.syr_word' ? (
											<img src={sort_icon_desc} alt="sort icon" height="15px" />
										) : null}
										{this.props.sorting === 'asc.syr_word' ? (
											<img src={sort_icon_asc} alt="sort icon" height="15px" />
										) : null}
									</p>
								</div>
								<div className="n-col arab-col t" onClick={() => this.props.onSort('arb_word')}>
									<p style={{ cursor: 'pointer' }}>
										Arabic
										{this.props.sorting === 'desc.arb_word' ? (
											<img src={sort_icon_desc} alt="sort icon" height="15px" />
										) : null}
										{this.props.sorting === 'asc.arb_word' ? (
											<img src={sort_icon_asc} alt="sort icon" height="15px" />
										) : null}
									</p>
								</div>
								<div className="n-col t x">
									<p>Meaning</p>
								</div>
								<div className="s-icon-col">
									{this.props.user.level === 'admin' ? (
										<Link
											to="/adminadd"
											onClick={() =>
												captureEvent(
													'Adding Name',
													'Clicked Add Name',
													'From Header (Plus Symbol)'
												)}
										>
											{/* <img src={add_icon} alt="add icon" height="25px" /> */}
											<Lottie
												options={{
													loop: true,
													animationData: Add
												}}
												isClickToPauseDisabled={true}
												height={30}
												width={30}
												style={{
													// backgroundColor: 'red',
													display: 'inline-block',
													verticalAlign: 'top'
												}}
											/>
										</Link>
									) : null}
								</div>
							</div>
						</div>
						<div>
							{this.props.names &&
								this.props.names.map((name) => (
									<NameBox
										key={name.id}
										name={name}
										onToggleInfo={this.handleToggleInfo}
										onDelete={this.props.onDelete}
										onAddFavorite={this.props.onAddFavorite}
										user={this.props.user}
										onEdit={this.props.onEdit}
										fave_list={this.props.fave_list}
										isLoggedIn={this.props.isLoggedIn}
										dialect={this.props.dialect}
										onConvertDialect={this.handleConvertDialect}
										expandingName={this.state.expandingName}
										onFeedbackChange={this.props.onFeedbackChange}
									/>
								))}
						</div>
						{this.props.namesCount === 0 ? (
							<div className="no-names-container">
								<div>
									<p className="text-info">
										There are no names available currently with these filters. You can{' '}
										<span onClick={this.props.onReset}>
											reset <img src={clear_icon} alt="clear icon" height="30px" />
										</span>{' '}
										the filters and try different ones,
									</p>
									<p className="text-info">or if you have a name you like us to add to our list. </p>
									<Link
										to="/add"
										onClick={() => {
											captureEvent(
												'Adding Name',
												'Clicked Add Name',
												'From Main Page (no names available)'
											);
											this.scrollToTop();
										}}
									>
										<button style={{ height: '40px' }} className="add-btn">
											{/* <img src={add_icon} alt="add icon" height="30px" /> Add a Name */}
											<Lottie
												options={{
													loop: true,
													animationData: Add
												}}
												isClickToPauseDisabled={true}
												height={30}
												width={30}
												style={{
													// backgroundColor: 'red',
													display: 'inline-block',
													verticalAlign: 'top'
												}}
											/>{' '}
											<div style={{ paddingTop: 3, display: 'inline-block' }}>Add a Name</div>
										</button>
									</Link>
								</div>
							</div>
						) : null}
						<Pagination
							namesCount={this.props.namesCount}
							currPage={this.props.currPage}
							pageSize={this.props.pageSize}
							onSelectPage={this.props.onSelectPage}
							onNext={this.props.onNext}
							onPrev={this.props.onPrev}
							startPage={this.props.startPage}
						/>
						<div className="count-container">
							<p>
								There&nbsp;
								{this.props.namesCount > 1 ? (
									<span>
										are <span style={{ color: '#74e6f3' }}>{this.props.namesCount}</span>
										&nbsp;names&nbsp;
									</span>
								) : (
									<span>
										is <span style={{ color: '#74e6f3' }}>{this.props.namesCount}</span>
										&nbsp;name&nbsp;
									</span>
								)}
								available.
							</p>
						</div>
					</React.Fragment>
				) : (
					<div className="no-names-container">
						<div>
							<p className="text-info">
								There are no names available currently with these filters. You can{' '}
								<span onClick={this.props.onReset}>
									reset <img src={clear_icon} alt="clear icon" height="30px" />
								</span>{' '}
								the filters and try different ones,
							</p>
							<p className="text-info">or if you have a name you like us to add to our list. </p>
							<Link
								to="/add"
								onClick={() => {
									captureEvent(
										'Adding Name',
										'Clicked Add Name',
										'From Main Page (no names available)'
									);
									this.scrollToTop();
								}}
							>
								<button style={{ height: '40px' }} className="add-btn">
									{/* <img src={add_icon} alt="add icon" height="30px" /> Add a Name */}
									<Lottie
										options={{
											loop: true,
											animationData: Add
										}}
										isClickToPauseDisabled={true}
										height={30}
										width={30}
										style={{
											// backgroundColor: 'red',
											display: 'inline-block',
											verticalAlign: 'top'
										}}
									/>{' '}
									<div style={{ paddingTop: 3, display: 'inline-block' }}>Add a Name</div>
								</button>
							</Link>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default NamesContainer;
