// {
//   "API_ENDPOINT": "https://www.dargushti.com/api/v6",
//   "TOKEN": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfbmFtZSI6IlJlYWN0MSIsImlhdCI6MTU1NDQzMjQyM30.s_gJvRE7pGeENMJVcXPAJmOgOn7IiILrV49-kU0DYyE",
//   "GOOGLE_CLIENT_ID": "569574078270-41ip4mbudn149f24n59rurbrpotj0ale.apps.googleusercontent.com",
//   "EMAIL_API_ENDPOINT": "https://knuthapp.com/api/emailservice"
// }

module.exports = {
	API_ENDPOINT: 'https://names.betkanu.com/api/v6',
	API_KEY: 'API_KEY_SECRET',
	// API_ENDPOINT: `http://localhost:${process.env.PORT || 8000}/api/v6}`,
	// API_ENDPOINT:
	// "http://dargushtiapp-env.4ensn5agbf.us-east-2.elasticbeanstalk.com/api/v6",
	LOCAL_API_ENDPOINT: 'http://localhost:8000/api/v6',
	TOKEN: process.env.DARGUSHTITOKEN,
	GOOGLE_CLIENT_ID: '569574078270-41ip4mbudn149f24n59rurbrpotj0ale.apps.googleusercontent.com',
	EMAIL_API_ENDPOINT: 'https://knuthapp.com/api/emailservice',
	OPTIONS: {
		headers: {
			// authorization: 'Bearer ' + process.env.REACT_APP_DARGUSHTI_TOKEN
			'x-api-key': process.env.REACT_APP_DARGUSHTI_TOKEN
		}
	}
};
