import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './Toolbar.css';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import logo from '../../New_Icons/Dargushto_Logo.png';
import logo_header from '../../New_Icons/Dargushto_Logo_header.png';
import logoNames from '../../New_Icons/Bet_Kanu_Names_logo_4.png';
// import west from '../../New_Icons/SVG_Icons/west.svg';
// import east from '../../New_Icons/SVG_Icons/east.svg';
import west from '../../New_Icons/SVG_Icons/west_white.svg';
import east from '../../New_Icons/SVG_Icons/east_white.svg';
import Star from '../../New_Icons/Star.json';
import Lottie from 'react-lottie';
import PlusOne from '../../New_Icons/PlusOne.json';
import '../../App.css';
import { initGA, capturePageview, captureEvent } from '../../Analytics/googleAnalytics';

const Toolbar = (props) => (
	<header className="toolbar">
		<nav className="toolbar_navigation">
			<div className="left_menu">
				<div className="toolbar_toggle-button">
					<DrawerToggleButton click={props.drawerClickHandler} />
				</div>
				<Link
					to="/favorite"
					className="desktop"
					onClick={() => captureEvent('Favorites', 'Clicked Cart Logo', 'At Toolbar')}
					style={{ textDecoration: 'none' }}
				>
					<img src={logo} alt="logo" height="40px" />
					<span className="count desktop">{props.count}</span>
					<div className="star desktop">
						<Lottie
							options={{
								loop: true,
								animationData: Star
							}}
							isClickToPauseDisabled={true}
							height={25}
							width={25}
						/>
					</div>
				</Link>
			</div>
			<div className="middle_menu">
				<div className="toolbar_logo mobile">
					<Link to="/main" onClick={() => captureEvent('Navigation', 'Clicked Dargushti Logo', 'At Toolbar')}>
						<img src={logoNames} alt="logo" height="15px" />
					</Link>
				</div>
			</div>
			<div className="right_menu">
				<div className="mobile">
					<Link to="/favorite">
						<Lottie
							options={{
								loop: true,
								animationData: Star
							}}
							isClickToPauseDisabled={true}
							height={25}
							width={25}
						/>
					</Link>
				</div>
				{props.isLoggedIn ? (
					<div className="menu-item desktop">
						<Link
							to="/favorite"
							onClick={() => captureEvent('Navigation', 'Clicked Profile Logo', 'At Toolbar')}
						>
							<img src={props.user.image} alt="profile pic" className="photo" />
						</Link>
					</div>
				) : null}

				{props.dialect === 'Eastern' ? (
					<div className="menu-item ew" id="dialect_step">
						<img
							src={west}
							alt="west"
							height="45px"
							onClick={props.onToggleDialect}
							style={{ cursor: 'pointer' }}
						/>
					</div>
				) : (
					<div className="menu-item ew">
						<img
							src={east}
							alt="east"
							height="45px"
							onClick={props.onToggleDialect}
							style={{ cursor: 'pointer' }}
						/>
					</div>
				)}
				<Link
					to="/main"
					className="menu-item desktop"
					onClick={() => captureEvent('Navigation', 'Clicked Home Tab', 'At Toolbar')}
				>
					Home
				</Link>
				<Link
					to="/about"
					className="menu-item desktop"
					onClick={() => captureEvent('Navigation', 'Clicked About Tab', 'At Toolbar')}
				>
					About
				</Link>
				{/* {props.isLoggedIn ? (
					<div
						className="menu-item desktop"
						onClick={() => {
							captureEvent('Auth', 'Clicked Logout', 'At Toolbar');
							props.onLogout();
						}}
					>
						Logout
					</div>
				) : (
					<Link
						to="/login"
						className="menu-item desktop"
						onClick={() => {
							captureEvent('Auth', 'Clicked Login', 'At Toolbar');
						}}
					>
						Login
					</Link>
				)} */}
				<Link
					to="/support"
					className="menu-item desktop"
					onClick={() => {
						captureEvent('Navigation', 'Clicked Support', 'At Toolbar');
					}}
				>
					Support
				</Link>
				<Link
					to="/contact"
					className="menu-item desktop"
					onClick={() => {
						captureEvent('Navigation', 'Clicked Support', 'At Toolbar');
					}}
				>
					Contact
				</Link>

				{props.isLoggedIn ? (
					<div
						className="menu-item desktop"
						onClick={() => {
							captureEvent('Auth', 'Clicked Logout', 'At Toolbar');
							props.onLogout();
						}}
					>
						Logout
					</div>
				) : (
					<Link
						to="/login"
						className="menu-item desktop"
						onClick={() => {
							captureEvent('Auth', 'Clicked Login', 'At Toolbar');
						}}
					>
						Login
					</Link>
				)}
			</div>
		</nav>
	</header>
);

export default withRouter(Toolbar);
