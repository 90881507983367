import React, { Component } from 'react';
import Nav from './components/Nav';
import Main from './components/Main';
import Footer from './components/Footer';
import Login from './components/Login';
import NotFound from './components/NotFound';
import Toolbar from './components/Toolbar/Toolbar';
import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from './components/Backdrop/Backdrop';
import { Route, Redirect, Switch } from 'react-router-dom';
import './App.css';
import Callback from './components/Callback';
import http from './services/httpService';
import config from './services/config.js';
import Add from './components/Add';
import SuccessAdd from './components/SuccessAdd';
import Edit from './components/Edit';
import AdminAdd from './components/AdminAdd';
import Favorite from './components/Favorite';
import cookies from 'react-cookies';
import Support from './components/Support';
import Contact from './components/Contact';
import { Link } from 'react-router-dom';

import About from './components/About/About';
import { initGA, capturePageview, captureEvent } from './Analytics/googleAnalytics';
import logoNames from './New_Icons/Bet_Kanu_Names_logo_4.png';

// import cookie from "react-cookies";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoggedIn: false,
			user: {},
			fave_list: [],
			temp_list: [],
			save_list: false,
			sideDrawerOpen: false,
			dialect: 'Eastern',
			feedbackMessage: ''
		};
		// this.handleCheckSession();
	}
	componentDidMount() {
		initGA();
		capturePageview();
		this.handleCheckCookie();
		this.serverGetFavorites();
	}
	componentDidUpdate(prevProps, prevState) {}
	serverRequestLogin = async (google_user) => {
		if (!this.state.save_list) {
			http.post(config.API_ENDPOINT + '/auth/reg2', google_user).then((response) => {
				let user = response.data.result;
				cookies.save('token', response.data.token, { path: '/' });
				cookies.save('level', user.level, { path: '/' });
				cookies.save('id', user.id, { path: '/' });
				cookies.save('image', user.image, { path: '/' });
				cookies.save('first_name', user.first_name, { path: '/' });
				cookies.save('email', user.email, { path: '/' });
				this.setState({
					user: {
						level: cookies.load('level'),
						image: cookies.load('image'),
						id: cookies.load('id'),
						first_name: cookies.load('first_name')
					},
					isLoggedIn: true
				});
				this.serverGetFavorites();
			});
		} else {
			http.post(config.API_ENDPOINT + '/auth/reg2', google_user).then((response) => {
				cookies.save('token', response.data.token, { path: '/' });
				http
					.post(config.API_ENDPOINT + '/favorites/replace', this.state.fave_list, {
						headers: { authorization: 'Bearer ' + cookies.load('token') }
					})
					.then((response2) => {
						let user = response.data.result;

						cookies.save('level', user.level, { path: '/' });
						cookies.save('id', user.id, { path: '/' });
						cookies.save('image', user.image, { path: '/' });
						cookies.save('first_name', user.first_name, { path: '/' });

						this.setState({
							user: {
								level: cookies.load('level'),
								image: cookies.load('image'),
								id: cookies.load('id')
							},
							isLoggedIn: true,
							save_list: false
						});
					});
			});
		}
	};
	/// GOOGLE AUTH METHODS
	handleFailure = (error) => {
		alert(error);
		return <Redirect to={'/login'} />;
	};
	handleGoogleResponse = (response) => {
		//console.log('google response:', response);
		let google_user = {};
		if (response.w3) {
			google_user = {
				first_name: response.w3.ofa,
				last_name: response.w3.wea,
				email: response.w3.U3,
				image: response.w3.Paa,
				google_id: response.googleId
			};
		} else {
			google_user = {
				first_name: response.profileObj.givenName,
				last_name: response.profileObj.familyName,
				email: response.profileObj.email,
				image: response.profileObj.imageUrl,
				google_id: response.profileObj.googleId
			};
		}

		this.serverRequestLogin(google_user);
		// this.props.history.push('/')
	};
	handleSaveList = () => {
		this.setState({ save_list: true });
	};
	handleLogout = async () => {
		captureEvent('Auth', 'Logged Out', 'N/A');
		this.scrollToTop();

		cookies.remove('token', { path: '/' });
		cookies.remove('id', { path: '/' });
		cookies.remove('level', { path: '/' });
		cookies.remove('image', { path: '/' });
		cookies.remove('first_name', { path: '/' });
		cookies.remove('email', { path: '/' });

		this.setState({
			isLoggedIn: false,
			user: {},
			fave_list: [],
			save_list: false
		});
	};
	//this one is old
	handleCheckSession = async () => {
		http.get(config.API_ENDPOINT + '/auth/check').then((result) => {
			this.setState({
				isLoggedIn: result.data.results.isLoggedIn,
				user: result.data.results.user
			});

			// this.serverGetFavorites();
			return result.data.results.isLoggedIn;
		});
	};
	handleCheckCookie = () => {
		if (cookies.load('token')) {
			this.setState({
				isLoggedIn: true,
				user: {
					level: cookies.load('level'),
					id: cookies.load('id'),
					image: cookies.load('image'),
					first_name: cookies.load('first_name')
				}
			});
		} else {
			this.setState({
				isLoggedIn: false,
				user: {}
			});
		}
	};
	serverGetFavorites = () => {
		if (cookies.load('token')) {
			http
				.get(config.API_ENDPOINT + '/favorites', {
					headers: { authorization: 'Bearer ' + cookies.load('token') }
				})
				.then((response) => {
					this.setState({ fave_list: response.data.result });
					return response.data.result;
				})
				.catch((err) => {});
		}
		// }
	};
	handleAddFavorite = (name) => {
		if (this.state.isLoggedIn) {
			if (this.state.fave_list.find((nameList) => nameList.id === name.id)) {
				captureEvent('Favorites', 'Removed Star', 'While Logged In');
				this.handleRemoveFavorite(name.id);
			} else {
				this.setState({ fave_list: [ ...this.state.fave_list, name ] });
				captureEvent('Favorites', 'Added Star', 'While Logged In');

				http.post(
					config.API_ENDPOINT + '/favorites/' + name.id,
					{},
					{ headers: { authorization: 'Bearer ' + cookies.load('token') } }
				);
			}
		} else {
			if (this.state.fave_list.find((nameList) => nameList.id === name.id)) {
				captureEvent('Favorites', 'Removed Star', 'While Logged Out');
				this.handleRemoveFavorite(name.id);
			} else {
				captureEvent('Favorites', 'Added Star', 'While Logged Out');

				var fave_list = [ ...this.state.fave_list, name ];
				this.setState({ fave_list });
			}
		}
	};
	handleRemoveFavorite = (name_id) => {
		if (this.state.isLoggedIn) {
			http.delete(config.API_ENDPOINT + '/favorites/' + name_id, {
				headers: { authorization: 'Bearer ' + cookies.load('token') }
			});
			// .then(response => {
			//   this.serverGetFavorites();
			// });
			// var fave_list = this.state.fave_list.filter(name => name.id !== name_id);
			// this.setState({ fave_list });
		} else {
			// var fave_list = this.state.fave_list.filter(name => name.id !== name_id);
			// this.setState({ fave_list });
		}
		var fave_list = this.state.fave_list.filter((name) => name.id !== name_id);
		this.setState({ fave_list });
	};
	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	handleTestLogin = () => {
		this.handleCheckCookie();

		http
			.get(config.API_ENDPOINT, {
				headers: { authorization: 'Bearer' + cookies.load('token') }
			})
			.then((response) => {});
	};
	drawerToggleClickHandler = () => {
		captureEvent('Navigation', 'Clicked Hamburger', 'N/A');
		this.setState({ sideDrawerOpen: !this.state.sideDrawerOpen });
	};
	backdropClickHandler = () => {
		this.setState({ sideDrawerOpen: false });
	};
	handleToggleDialect = () => {
		captureEvent('UX', 'Changed Dialect', 'To:' + this.state.dialect === 'Eastern' ? 'West' : 'East');
		this.state.dialect === 'Eastern'
			? this.setState({ dialect: 'Western' })
			: this.setState({ dialect: 'Eastern' });
	};
	onFeedbackChange = (txt) => {
		//console.log('text:', txt);
		this.setState({ feedbackMessage: txt });
	};
	render() {
		let backdrop = null;
		if (this.state.sideDrawerOpen) {
			backdrop = <Backdrop click={this.backdropClickHandler} />;
		}

		return (
			<div style={{ height: '100%' }}>
				<Toolbar
					drawerClickHandler={this.drawerToggleClickHandler}
					isLoggedIn={this.state.isLoggedIn}
					onLogout={this.handleLogout}
					user={this.state.user}
					count={this.state.fave_list.length}
					dialect={this.state.dialect}
					onToggleDialect={this.handleToggleDialect}
					// history={(to) => this.props.history.push(to)}
				>
					Toolbar
				</Toolbar>
				<SideDrawer
					show={this.state.sideDrawerOpen}
					drawerClickHandler={this.drawerToggleClickHandler}
					isLoggedIn={this.state.isLoggedIn}
					onLogout={this.handleLogout}
					user={this.state.user}
					count={this.state.fave_list.length}
					dialect={this.state.dialect}
					onToggleDialect={this.handleToggleDialect}
				/>
				{backdrop}
				<Nav
					isLoggedIn={this.state.isLoggedIn}
					onLogout={this.handleLogout}
					user={this.state.user}
					count={this.state.fave_list.length}
				/>

				<div className="main-wrapper">
					<div className="logo-container">
						<Link to="/main">
							{/* <img className="main-logo" src={logo} alt="big logo" /> */}
							<img className="main-logo" id="logo_step" src={logoNames} alt="big logo" />
							{/* <div className="tag-line">MY CRADLE FOR SYRIAC NAMES</div> */}
						</Link>
					</div>
					<Switch>
						<Route
							path="/main"
							render={(props) => (
								<Main
									{...props}
									user={this.state.user}
									fave_list={this.state.fave_list}
									onAddFavorite={this.handleAddFavorite}
									isLoggedIn={this.state.isLoggedIn}
									onTestLogin={this.handleTestLogin}
									dialect={this.state.dialect}
									onFeedbackChange={this.onFeedbackChange}
								/>
							)}
						/>
						<Route
							path="/support"
							render={(props) => <Support {...props} onScrollToTop={this.scrollToTop} />}
						/>
						<Route
							path="/contact"
							render={(props) => (
								<Contact
									{...props}
									onScrollToTop={this.scrollToTop}
									feedbackMessage={this.state.feedbackMessage}
									onFeedbackChange={this.onFeedbackChange}
									isLoggedIn={this.state.isLoggedIn}
								/>
							)}
						/>
						<Route
							path="/about"
							render={(props) => <About {...props} onScrollToTop={this.scrollToTop} />}
						/>

						<Route
							path="/login"
							render={(props) => (
								<Login
									{...props}
									onGoogleResponse={this.handleGoogleResponse}
									onFailur={this.handleFailure}
									isLoggedIn={this.state.isLoggedIn}
									user={this.state.user}
								/>
							)}
						/>
						<Route
							path="/add"
							render={(props) => (
								<Add {...props} isLoggedIn={this.state.isLoggedIn} user={this.state.user} />
							)}
						/>
						<Route
							path="/edit/:id"
							render={(props) => (
								<Edit {...props} isLoggedIn={this.state.isLoggedIn} user={this.state.user} />
							)}
						/>
						<Route
							path="/adminadd"
							render={(props) => (
								<AdminAdd {...props} isLoggedIn={this.state.isLoggedIn} user={this.state.user} />
							)}
						/>
						<Route
							path="/favorite"
							render={(props) => (
								<Favorite
									{...props}
									isLoggedIn={this.state.isLoggedIn}
									user={this.state.user}
									fave_list={this.state.fave_list}
									onRemoveFavorite={this.handleRemoveFavorite}
									onSaveList={this.handleSaveList}
									onGoogleResponse={this.handleGoogleResponse}
									onGetFavorites={this.serverGetFavorites}
									dialect={this.state.dialect}
								/>
							)}
						/>

						<Route path="/callback" component={Callback} />
						<Route path="/success" render={(props) => <SuccessAdd {...props} user={this.state.user} />} />

						<Route path="/not-found" component={NotFound} />
						<Route path="/betkanu" component={() => (window.location = 'http://www.betkanu.com')} />
						<Route
							path="/youtubechannel"
							component={() =>
								(window.location = 'https://www.youtube.com/channel/UCm1mSD6ynKK7vQAHtFJEHeQ')}
						/>
						<Route
							path="/familyfinger"
							component={() =>
								(window.location =
									'https://www.youtube.com/watch?v=oJHar7NKdlg&list=PLsfZA-ZA9qwpDN7wgPMWYE4R9tvfysV2f&index=9')}
						/>
						<Route
							path="/ninomia"
							component={() =>
								(window.location =
									'https://www.youtube.com/watch?v=5aWN7PelajA&list=PLsfZA-ZA9qwrYq1XMkYA3L8O8iLK_naLo')}
						/>
						<Route
							path="/jesussong"
							component={() => (window.location = 'https://www.youtube.com/watch?v=eFdMuhNMNfM')}
						/>

						<Redirect from="/" exact to="/main" />
						<Redirect to="/not-found" />
					</Switch>
				</div>
				<div className="footer-wrapper">
					<Footer />
				</div>
			</div>
		);
	}
}

export default App;
