import React, { Component } from "react";
// import logo from "../New_Icons/Dargushto_Logo_header.png";
import logo from "../New_Icons/Dargushto_Logo.png";
import logo_header from "../New_Icons/Dargushto_Logo_header.png";


import { Link } from "react-router-dom";
class Nav extends Component {
  state = {
    message: "nothing from server yet."
  };
  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  render() {
    return (
      < React.Fragment >

        <div className="nav-container">
          <Link className="logo-big" to="/main" onClick={this.scrollToTop} >
            <img src={logo} alt="logo" height="40px" />
          </Link>
          <Link className="logo-small" to="/main" onClick={this.scrollToTop}>
            <img src={logo_header} alt="logo" height="40px" />

            {/* <img src={logo_small} alt="logo_small" height="40px" /> */}
          </Link>
          <Link to="/main">
            <div className="tag-line">MY CRADLE FOR SYRIAC NAMES</div>
          </Link>
          {/* <Link className="nav-btn" to="/main" onClick={this.scrollToTop} style={{ textDecoration: 'none' }}>
            Home
          </Link> */}
          {this.props.isLoggedIn ? (
            <span className="" onClick={this.props.onLogout} >
              <Link className="nav-btn" to="/main" style={{ textDecoration: 'none' }}>
                Logout
              </Link>
            </span>
          ) : (
              <span onClick={this.scrollToTop}>
                <Link className="nav-btn" to="/login" style={{ textDecoration: 'none' }}>
                  Login
              </Link>
              </span>
            )}
          <Link className="nav-btn " to="/favorite" onClick={this.scrollToTop} style={{ textDecoration: 'none' }}>
            {/* <img src={cradle_icon} alt="cradle Icon" height="30px" /> */}
            Cart:{" "}{this.props.count}
          </Link >
          {this.props.isLoggedIn ? <Link to="/favorite" onClick={this.scrollToTop} ><img className="photo" src={this.props.user.image} alt="profile pic" /> </Link> : null}
          {/* <div className="steps-container" onClick={this.scrollToTop}>
            <img src={baby_steps} alt="baby steps icon" />
            <p>The Easiest Place to Find Syriac Name For Your Newborn</p>
          </div> */}
          {/* <Link to="/main">
            <img className="main-logo" src={logo} alt="big logo" />
            <div className="tag-line">MY CRADLE FOR SYRIAC NAMES</div>
          </Link> */}

        </div>
      </React.Fragment >
    );
  }
}

export default Nav;
